import React, {
	useEffect,
	useState,
	Dispatch as ReactDispatch,
	SetStateAction,
} from 'react';
import { CircularProgress } from '@mui/material';
import { Dispatch } from 'redux';
import * as Style from './register-password-setup.styles';
import {
	CheckBox,
	Help,
	ContentCopy,
	Check,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import { UserOnboardingVerificationResponseSchema } from '../../../schemas/user/new-user.schemas';
import { z } from 'zod';
import { createStructuredSelector } from 'reselect';
import {
	selectChangePasswordError,
	selectChangePasswordResponseData,
	selectCurrentUser,
	selectIsChangingResetPassword,
	selectIsProcessingLogin,
	selectLoginError,
	selectResourceTokens,
	selectVerifiedUser,
} from '../../../redux/user/user.selectors';
import { connect } from 'react-redux';
import { UserOutputSchema } from '../../../schemas/user/manage-users.schemas';
import { LoginResponseSchema } from '../../../schemas/user/authentication.schemas';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
	changeVerifiedUserPasswordStart,
	UserActionInterface,
	userLoginStart,
} from '../../../redux/user/user.actions';
import {
	PersonalProfileChangePasswordSchema,
	PersonalProfileOutputSchema,
} from '../../../schemas/user/manage-self.schemas';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';

interface RegisterPasswordSetupProps {
	// ============= PROPS =============
	// Set the active step of the register process to 2 upon successful register start
	setActiveStep: ReactDispatch<SetStateAction<number>>;

	// ============= REDUX PROPS =============
	verifiedUser: z.infer<
		typeof UserOnboardingVerificationResponseSchema
	> | null;
	currentUser: z.infer<typeof UserOutputSchema> | null;
	resourceTokens: z.infer<typeof LoginResponseSchema> | null;
	isProcessingLogin: boolean;
	loginError: AxiosError | string | null;

	isChangingVerifiedUserPassword: boolean;
	changeVerifiedUserPasswordError: AxiosError | string | null;
	changeVerifiedUserPasswordResponse: z.infer<
		typeof PersonalProfileOutputSchema
	> | null;

	// ============= REDUX ACTIONS =============
	userLoginStart: (username: string, password: string) => UserActionInterface;
	changeVerifiedUserPasswordStart: (
		changePasswordData: z.infer<typeof PersonalProfileChangePasswordSchema>,
		resourceTokens: z.infer<typeof LoginResponseSchema>
	) => UserActionInterface;
}

type changePasswordSchema = z.infer<typeof PersonalProfileChangePasswordSchema>;

const RegisterPasswordSetup = (props: RegisterPasswordSetupProps) => {
	const navigate = useNavigate();
	// Set random password when user is verified
	const [randomPassword, setRandomPassword] = React.useState<string>('');

	useEffect(() => {
		if (props.verifiedUser !== null) {
			setRandomPassword(props.verifiedUser.random_password);
		}
	});

	const [openModal, setOpenModal] = React.useState<boolean>(false);

	const handleModalClose = () => {
		setOpenModal(false);
	};

	// =================== Copy temp password to clipboard ===================
	const copyToClipboard = () => {
		navigator.clipboard.writeText(randomPassword);
	};
	const [copied, setCopied] = React.useState<boolean>(false);

	const handleCopyClick = () => {
		copyToClipboard();
		setCopied(true);
	};

	const handleSkipClick = () => {
		if (props.verifiedUser !== null) {
			props.userLoginStart(
				props.verifiedUser.username,
				props.verifiedUser.random_password
			);
		}
	};

	// Enter application upon skipping or continuing with new password
	useEffect(() => {
		if (props.resourceTokens !== null) {
			navigate('/auth/loading');
		}
	}, [props.resourceTokens]);

	// =================== SUBMIT NEW PASSWORD HANDLER ===================

	const methods = useForm<changePasswordSchema>({
		resolver: zodResolver(PersonalProfileChangePasswordSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	const handleChangePassword: SubmitHandler<changePasswordSchema> = (
		data
	) => {
		if (props.verifiedUser !== null) {
			props.changeVerifiedUserPasswordStart(
				data as changePasswordSchema,
				props.verifiedUser.tokens as z.infer<typeof LoginResponseSchema>
			);
		}
	};

	// Navigate inside application once password is changed
	useEffect(() => {
		if (props.changeVerifiedUserPasswordResponse !== null) {
			props.userLoginStart(
				props.changeVerifiedUserPasswordResponse.username,
				methods.getValues(`new_password`)
			);
		}
	}, [props.changeVerifiedUserPasswordResponse]);

	// On render, set the temporary password
	useEffect(() => {
		if (props.verifiedUser !== null) {
			methods.setValue(
				`old_password`,
				props.verifiedUser.random_password
			);
		}
	}, []);

	// =================== PASSWORD STRENGTH CHECKER ===================
	useEffect(() => {
		const password = methods.watch(`new_password`);
		setPasswordStrength({
			hasEightCharacters: password.length >= 8,
			hasUpperAndLowercase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
			hasNumber: /\d/.test(password),
			hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
		});
	}, [methods.watch(`new_password`)]);

	// =================== PASSWORD HANDLING ===================
	const [showPassword, setShowPassword] = useState(false);

	// Password requirements popup
	const [showPopup, setShowPopup] = useState(false);
	const [passwordStrength, setPasswordStrength] = useState({
		hasEightCharacters: false,
		hasUpperAndLowercase: false,
		hasNumber: false,
		hasSpecialChar: false,
	});

	// Check how many of the password strength conditions are met
	const trueValues = Object.values(passwordStrength).filter(
		(value) => value === true
	);
	const isAtLeastOneTrue = trueValues.length >= 1;
	const isAtLeastTwoTrue = trueValues.length >= 2;
	const isAtLeastThreeTrue = trueValues.length >= 3;
	const isAllTrue = trueValues.length >= 4;

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Style.Container
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
		>
			<CheckBox className='checkMark' />
			<Style.HeadingContainer>
				<Style.Heading>Account Verified</Style.Heading>
				<Style.SubHeading>
					Set up a password for your account.
				</Style.SubHeading>
			</Style.HeadingContainer>
			<Style.PasswordForm
				onSubmit={methods.handleSubmit(handleChangePassword)}
			>
				<Style.InputFieldsContainer>
					<Style.NewPasswordContainer>
						<Style.InputField>
							<Style.InputFieldLabel>
								New Password
							</Style.InputFieldLabel>
							<Style.InputFieldInput
								type={showPassword ? 'text' : 'password'}
								placeholder='New Password'
								{...methods.register(`new_password`)}
								onFocus={() => setShowPopup(true)}
								onBlur={() => {
									setShowPopup(false);
									methods.trigger(`new_password`);
								}}
								style={
									methods.formState.errors.new_password && {
										border: '2px solid red',
										padding: '7px',
									}
								}
							/>
							<Style.InputFieldButton
								onClick={toggleShowPassword}
							>
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</Style.InputFieldButton>
							<ErrorMessage
								errors={methods.formState.errors}
								name='new_password'
								render={({ message }) => (
									<Style.Error>{message}</Style.Error>
								)}
							/>
						</Style.InputField>
						<Style.RequirementsContainer
							style={{
								display: showPopup === true ? 'block' : 'none',
							}}
						>
							<Style.PasswordRequirementsPopup>
								<Style.PasswordLength>
									Must have at least 8 characters.
								</Style.PasswordLength>
								<Style.LineContainer>
									<Style.Line
										color={
											isAtLeastOneTrue ? '#fc7f12' : ''
										}
									/>
									<Style.Line
										color={
											isAtLeastTwoTrue ? '#fdbe00' : ''
										}
									/>
									<Style.Line
										color={
											isAtLeastThreeTrue ? '#aee63e' : ''
										}
									/>
									<Style.Line
										color={isAllTrue ? '#24a33d' : ''}
									/>
								</Style.LineContainer>
								<Style.Description>
									It&apos;s better to have:{' '}
								</Style.Description>
								<Style.PasswordRequirementsList>
									<Style.ListItem
										className={
											passwordStrength.hasUpperAndLowercase
												? 'met'
												: ''
										}
									>
										Upper & lowercase letters
									</Style.ListItem>
									<Style.ListItem
										className={
											passwordStrength.hasSpecialChar
												? 'met'
												: ''
										}
									>
										A Special Character (!@#$%^&*)
									</Style.ListItem>
									<Style.ListItem
										className={
											passwordStrength.hasNumber
												? 'met'
												: ''
										}
									>
										Number(s)
									</Style.ListItem>
								</Style.PasswordRequirementsList>
							</Style.PasswordRequirementsPopup>
						</Style.RequirementsContainer>
					</Style.NewPasswordContainer>
					<Style.InputField>
						<Style.InputFieldLabel>
							Confirm New Password
						</Style.InputFieldLabel>
						<Style.InputFieldInput
							type={showPassword ? 'text' : 'password'}
							placeholder='Confirm New Password'
							{...methods.register(`confirm_new_password`)}
							style={
								methods.formState.errors
									.confirm_new_password && {
									border: '2px solid red',
									padding: '7px',
								}
							}
						/>
						<ErrorMessage
							errors={methods.formState.errors}
							name='confirm_new_password'
							render={({ message }) => (
								<Style.Error>{message}</Style.Error>
							)}
						/>
					</Style.InputField>
				</Style.InputFieldsContainer>
				<Style.BtnContainer>
					<Style.Btn
						type='submit'
						onClick={methods.handleSubmit(handleChangePassword)}
					>
						{props.isChangingVerifiedUserPassword ||
						props.isProcessingLogin ? (
							<CircularProgress
								size={24}
								style={{ color: '#FFF' }}
							/>
						) : (
							'Continue'
						)}
					</Style.Btn>
				</Style.BtnContainer>
			</Style.PasswordForm>
			<Style.SkipContainer>
				<Style.SubHeading>OR</Style.SubHeading>
				<Style.Desc>
					Skip and use this randomly generated password:{' '}
					<Style.TempPassword>{randomPassword}</Style.TempPassword>
				</Style.Desc>
			</Style.SkipContainer>
			<Style.Btn type='button' onClick={() => setOpenModal(true)}>
				Skip
			</Style.Btn>
			<Style.SkipModal
				open={openModal}
				onClose={handleModalClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Style.ModalContainer>
					<Help style={{ fontSize: '60px', color: '#fdbe00' }} />
					<Style.ModalHeading>
						Are you sure you want to skip?
					</Style.ModalHeading>
					<Style.ModalDesc>
						If so, that&apos;s okay! You can change your password
						within the Profile Settings inside the application.{' '}
						<br />
						<br />
						Please remember your temporary password for now:
						<Style.CopyPassword>
							<Style.TempPassword
								style={{ color: 'red', fontSize: '20px' }}
							>
								{randomPassword}
							</Style.TempPassword>
							<Style.CopyBtn onClick={handleCopyClick}>
								{copied ? <Check /> : <ContentCopy />}
							</Style.CopyBtn>
						</Style.CopyPassword>
					</Style.ModalDesc>
					<Style.BtnContainer>
						<Style.Btn
							type='button'
							onClick={handleSkipClick}
							style={{ minWidth: '195px', maxWidth: '195px' }}
						>
							{props.isProcessingLogin ? (
								<CircularProgress
									size={24}
									style={{ color: '#FFF' }}
								/>
							) : (
								'Confirm Skip'
							)}
						</Style.Btn>
						<Style.CancelBtn
							type='button'
							onClick={handleModalClose}
						>
							Cancel
						</Style.CancelBtn>
					</Style.BtnContainer>
				</Style.ModalContainer>
			</Style.SkipModal>
		</Style.Container>
	);
};

const mapStateToProps = createStructuredSelector({
	verifiedUser: selectVerifiedUser,
	currentUser: selectCurrentUser,
	resourceTokens: selectResourceTokens,
	loginError: selectLoginError,
	isProcessingLogin: selectIsProcessingLogin,

	isChangingVerifiedUserPassword: selectIsChangingResetPassword,
	changeVerifiedUserPasswordError: selectChangePasswordError,
	changeVerifiedUserPasswordResponse: selectChangePasswordResponseData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	userLoginStart: (username: string, password: string) =>
		dispatch(userLoginStart(username, password)),
	changeVerifiedUserPasswordStart: (
		changePasswordData: z.infer<typeof PersonalProfileChangePasswordSchema>,
		resourceTokens: z.infer<typeof LoginResponseSchema>
	) =>
		dispatch(
			changeVerifiedUserPasswordStart(changePasswordData, resourceTokens)
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegisterPasswordSetup);
