import {
	ForgotPasswordEmailSchema,
	GenericResponseSchema,
	PersonalProfileResetPasswordInputSchema,
} from './../../schemas/user/manage-self.schemas';
import { APP_ENV } from './../../env/app.env';
import {
	ForgotPasswordOutputSchema,
	LoginResponseSchema,
	LogoutResponseSchema,
} from './../../schemas/user/authentication.schemas';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
	changeVerifiedUserPasswordSuccess,
	changeVerifiedUserPasswordFailure,
	unverifiedUserRegisterFailure,
	unverifiedUserRegisterSuccess,
	unverifiedUserUpdateEmailSuccess,
	UserActionInterface,
	userLoginFailure,
	userLoginSuccess,
	userLogoutFailure,
	userLogoutSuccess,
	userRefreshTokenFailure,
	userRefreshTokenSuccess,
	userVerificationFailure,
	userVerificationSuccess,
	forgotPasswordChangeSuccess,
	forgotPasswordChangeFailure,
	sendForgetPasswordEmailSuccess,
	sendForgetPasswordEmailFailure,
} from './user.actions';
import { z } from 'zod';
import { UserOutputSchema } from '../../schemas/user/manage-users.schemas';
import {
	UnverifiedUserCreateSchema,
	UnverifiedUserOutputSchema,
	UnverifiedUserVerificationSchema,
	UserOnboardingVerificationResponseSchema,
} from '../../schemas/user/new-user.schemas';
import UserActionTypes from './user.types';
import {
	PersonalProfileChangePasswordSchema,
	PersonalProfileOutputSchema,
} from '../../schemas/user/manage-self.schemas';

// ============= HANDLE LOGIN PROCESS ===================

function* handleUserLoginSaga(action: UserActionInterface) {
	const payload = action.payload as {
		username: string;
		password: string;
	};

	try {
		// LOGIN USER
		const loginResponse: AxiosResponse = yield axios.post(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/authentication/login`,
			{
				username: payload.username,
				password: payload.password,
			},
			{
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
			}
		);

		const resourceTokens = loginResponse.data as z.infer<
			typeof LoginResponseSchema
		>;

		// Fetch User meta-data
		const userResponse: AxiosResponse = yield axios.get(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/manage-self/get/me`,
			{
				headers: {
					Authorization: `Bearer ${resourceTokens.access_token}`,
				},
			}
		);

		const currentUser = userResponse.data as z.infer<
			typeof UserOutputSchema
		>;

		// Call success
		yield put(userLoginSuccess(resourceTokens, currentUser));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(userLoginFailure(error as AxiosError));
		} else {
			yield put(userLoginFailure(JSON.stringify(error)));
		}
	}
}

// ============ HANDLE REGISTRATION PROCESS ==================

function* handleUserRegistrationSaga(action: UserActionInterface) {
	const payload = action.payload as z.infer<
		typeof UnverifiedUserCreateSchema
	>;

	try {
		// Register Unverified User
		const registerResponse: AxiosResponse = yield axios.post(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/new-user/create`,
			payload,
			{
				headers: {
					accept: 'application/json',
					'content-type': 'application/json',
				},
			}
		);

		const { user_id } = registerResponse.data as z.infer<
			typeof UnverifiedUserOutputSchema
		>;

		// Send verification email
		yield call(sendVerificationEmail, user_id);

		// Call success
		yield put(
			unverifiedUserRegisterSuccess(
				registerResponse.data as z.infer<
					typeof UnverifiedUserOutputSchema
				>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(unverifiedUserRegisterFailure(error as AxiosError));
		} else {
			yield put(unverifiedUserRegisterFailure(JSON.stringify(error)));
		}
	}
}

// Helper function to send verification email
function* sendVerificationEmail(userId: string) {
	const sendEmailUrl = `${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/new-user/comms/${userId}/send-verify-email`;
	yield axios.post(sendEmailUrl);
}

// ============= HANDLE UPDATE UNVERIFIED EMAIL PROCESS ===================
function* handleUpdateUnverifiedEmailSaga(action: UserActionInterface) {
	const payload = action.payload as {
		userId: string;
		unverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>;
	};

	try {
		// Update Unverified User
		const updateResponse: AxiosResponse = yield axios.put(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/new-user/update/${payload.userId}`,
			payload.unverifiedUserData
		);

		// Send verification email
		yield call(sendVerificationEmail, payload.userId);

		// call success
		yield put(
			unverifiedUserUpdateEmailSuccess(
				updateResponse.data as z.infer<
					typeof UnverifiedUserOutputSchema
				>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(unverifiedUserRegisterFailure(error as AxiosError));
		} else {
			yield put(unverifiedUserRegisterFailure(JSON.stringify(error)));
		}
	}
}

// ============= HANDLE USER VERIFICATION PROCESS ===================
function* handleUserVerificationSaga(action: UserActionInterface) {
	const payload = action.payload as {
		userId: string;
		userVerificationData: z.infer<typeof UnverifiedUserVerificationSchema>;
	};

	try {
		console.log(payload.userVerificationData);
		// Verify User
		const verifyResponse: AxiosResponse = yield axios.post(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/new-user/verify/${payload.userId}`,
			payload.userVerificationData
		);

		// Call success
		yield put(
			userVerificationSuccess(
				verifyResponse.data as z.infer<
					typeof UserOnboardingVerificationResponseSchema
				>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(userVerificationFailure(error as AxiosError));
			console.log(error);
		} else {
			yield put(userVerificationFailure(JSON.stringify(error)));
			console.log(error);
		}
	}
}

// ============= HANDLE VERIFIED USER CHANGE PASSWORD PROCESS ===================
function* handleVerifiedUserChangePasswordSaga(action: UserActionInterface) {
	const payload = action.payload as {
		changePasswordData: z.infer<typeof PersonalProfileChangePasswordSchema>;
		resourceTokens: z.infer<typeof LoginResponseSchema>;
	};

	try {
		// Change Password
		const changeResponse: AxiosResponse = yield axios.put(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/manage-self/password/update`,
			payload.changePasswordData,
			{
				headers: {
					Authorization: `Bearer ${payload.resourceTokens.access_token}`,
				},
			}
		);

		// Call success
		yield put(
			changeVerifiedUserPasswordSuccess(
				changeResponse.data as z.infer<
					typeof PersonalProfileOutputSchema
				>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(changeVerifiedUserPasswordFailure(error as AxiosError));
		} else {
			yield put(changeVerifiedUserPasswordFailure(JSON.stringify(error)));
		}
	}
}

// ============= HANDLE FORGOT PASSWORD EMAIL PROCESS ===================
function* handleForgotPasswordEmailSaga(action: UserActionInterface) {
	const payload = action.payload as {
		email: z.infer<typeof ForgotPasswordEmailSchema>;
	};

	try {
		// Send forgot password email
		const forgotPasswordResponse: AxiosResponse = yield axios.post(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/authentication/forgot-password/${payload.email}`
		);

		// Call success
		yield put(
			sendForgetPasswordEmailSuccess(
				forgotPasswordResponse.data as z.infer<
					typeof ForgotPasswordOutputSchema
				>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(sendForgetPasswordEmailFailure(error as AxiosError));
		} else {
			yield put(sendForgetPasswordEmailFailure(JSON.stringify(error)));
		}
	}
}

// ============= HANDLE FORGOT PASSWORD CHANGE PROCESS ===================
function* handleForgotPasswordChangeSaga(action: UserActionInterface) {
	const payload = action.payload as {
		verificationCode: string;
		resetPasswordData: z.infer<
			typeof PersonalProfileResetPasswordInputSchema
		>;
	};

	try {
		// Send forgot password email
		const forgotPasswordResponse: AxiosResponse = yield axios.put(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/internal/manage-self/password/reset/${payload.verificationCode}`,
			payload.resetPasswordData
		);

		// Call success
		yield put(
			forgotPasswordChangeSuccess(
				forgotPasswordResponse.data as z.infer<
					typeof GenericResponseSchema
				>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(forgotPasswordChangeFailure(error as AxiosError));
		} else {
			yield put(forgotPasswordChangeFailure(JSON.stringify(error)));
		}
	}
}

// ================ HANDLE LOGOUT PROCESS =======================
function* handleUserLogoutSaga(action: UserActionInterface) {
	const payload = action.payload as z.infer<typeof LoginResponseSchema>;
	try {
		// Logout User
		const logoutResponse: AxiosResponse = yield axios.post(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/authentication/logout`,
			payload,
			{
				headers: {
					Authorization: `Bearer ${payload.access_token}`,
				},
			}
		);

		// Call Success

		yield put(
			userLogoutSuccess(
				logoutResponse.data as z.infer<typeof LogoutResponseSchema>
			)
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(userLogoutFailure(error as AxiosError));
		} else {
			yield put(userLogoutFailure(JSON.stringify(error)));
		}
	}
}

// ================ HANDLE REFRESH TOKEN PROCESS =======================
function* userRefreshTokenSaga(action: UserActionInterface) {
	const payload = action.payload as z.infer<typeof LoginResponseSchema>;
	try {
		const refreshResponse: AxiosResponse = yield axios.post(
			`${APP_ENV.AUTH_MICROSERVICE_URL}/api/v1/authentication/refresh`,
			{
				access_token: payload.access_token,
				refresh_token: payload.refresh_token,
			}
		);
		const resourceTokens = refreshResponse.data as z.infer<
			typeof LoginResponseSchema
		>;
		yield put(userRefreshTokenSuccess(resourceTokens));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(userRefreshTokenFailure(error as AxiosError));
		} else {
			yield put(userRefreshTokenFailure(JSON.stringify(error)));
		}
	}
}

// =============== DEFINE SAGA LISTENERS =====================
function* onLoginProcess() {
	yield takeLatest(UserActionTypes.LOGIN_START, handleUserLoginSaga);
}

function* onRegistrationProcess() {
	yield takeLatest(
		UserActionTypes.REGISTER_START,
		handleUserRegistrationSaga
	);
}

function* onUnverifiedUserUpdateEmailProcess() {
	yield takeLatest(
		UserActionTypes.UPDATE_UNVERIFIED_EMAIL_START,
		handleUpdateUnverifiedEmailSaga
	);
}

function* onVerifiedUserChangePasswordProcess() {
	yield takeLatest(
		UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_START,
		handleVerifiedUserChangePasswordSaga
	);
}

function* onForgotPasswordEmailProcess() {
	yield takeLatest(
		UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_START,
		handleForgotPasswordEmailSaga
	);
}

function* onForgotPasswordChangeProcess() {
	yield takeLatest(
		UserActionTypes.FORGOT_PASSWORD_CHANGE_START,
		handleForgotPasswordChangeSaga
	);
}

function* onVerificationProcess() {
	yield takeLatest(
		UserActionTypes.USER_VERIFY_START,
		handleUserVerificationSaga
	);
}

function* onLogoutProcess() {
	yield takeLatest(UserActionTypes.USER_LOGOUT_START, handleUserLogoutSaga);
}

function* onRefreshTokenProcess() {
	yield takeLatest(
		UserActionTypes.USER_REFRESH_TOKEN_START,
		userRefreshTokenSaga
	);
}

// =============== EXPORT SAGA LISTENERS =====================
export function* userSaga() {
	yield all([
		call(onLoginProcess),
		call(onRegistrationProcess),
		call(onVerifiedUserChangePasswordProcess),
		call(onUnverifiedUserUpdateEmailProcess),
		call(onVerificationProcess),
		call(onForgotPasswordEmailProcess),
		call(onForgotPasswordChangeProcess),
		call(onLogoutProcess),
		call(onRefreshTokenProcess),
	]);
}
