import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TimelineContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const TimelineLine = styled(motion.div)`
	height: 100px;
	width: 3px;

	@media (max-width: 975px) {
		width: 2px;
	}

	display: block;
	background: #fff;
	background-position: bottom left;

	&:active {
		background-position: top left;
	}

	background: ${({ color }) =>
		`linear-gradient(to bottom, ${color} 50%, #FFF 50%)`};
	background-size: 100% 200%;
	transition: all 0.5s ease-out;
`;

export const TimelineCircle = styled(motion.div)`
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;

	@media (max-width: 975px) {
		width: 34px;
		height: 34px;
	}
`;

export const TimelineNumber = styled.div`
	font-size: 20px;
	font-weight: bold;
	color: #1a879b;
	z-index: 100;
`;

export const TimelineLabel = styled.div`
	font-size: 20px;
	width: 170px;
	color: #fff;
	margin-top: 8px;
	font-weight: 700;
	white-space: nowrap;

	@media (max-width: 975px) {
		font-size: 15px;
		width: 150px;
		margin-top: 8px;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 10px;
	width: 100%;
	top: 15px;
`;

export const CircleLineContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
