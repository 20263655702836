import React, {
	SetStateAction,
	Dispatch as ReactDispatch,
	useEffect,
} from 'react';
import * as Style from './register-start.styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
	UnverifiedUserCreateSchema,
	UnverifiedUserOutputSchema,
} from '../../../schemas/user/new-user.schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import {
	unverifiedUserRegisterStart,
	UserActionInterface,
} from '../../../redux/user/user.actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	selectIsProcessingRegister,
	selectRegisterError,
	selectUnverifiedRegisteredUser,
} from '../../../redux/user/user.selectors';
import { ErrorMessage } from '@hookform/error-message';
import { CircularProgress } from '@mui/material';

interface RegisterStartProps {
	// ========== REDUX PROPS ==========
	isProcessingRegister: boolean;
	registerError: AxiosError | string | null;
	unverifiedRegisteredUser: z.infer<typeof UnverifiedUserOutputSchema> | null;

	// ========== REDUX ACTIONS ==========
	unverifiedUserRegisterStart: (
		newUnverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>
	) => UserActionInterface;

	// ============= PROPS =============
	// Set the active step of the register process to 2 upon successful register start
	setActiveStep: ReactDispatch<SetStateAction<number>>;
	// Handle Toggle Event for swiching b/w login/register
	handleToggle: () => void;
}

type unverifiedUserRegisterStartSchema = z.infer<
	typeof UnverifiedUserCreateSchema
>;

const RegisterStart = (props: RegisterStartProps) => {
	// Useform hook for form validation
	const methods = useForm<unverifiedUserRegisterStartSchema>({
		resolver: zodResolver(UnverifiedUserCreateSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	// On Submit Event Handler for Register Start Process
	const onRegisterStart: SubmitHandler<unverifiedUserRegisterStartSchema> = (
		data
	) => {
		props.unverifiedUserRegisterStart(
			data as z.infer<typeof UnverifiedUserCreateSchema>
		);
	};

	useEffect(() => {
		// If the register process is successful, set the active step to 2
		if (props.unverifiedRegisteredUser !== null) {
			props.setActiveStep(2);
		}
	}, [props.unverifiedRegisteredUser]);

	// Error handling
	const [errorText, setErrorText] = React.useState<string>('');
	useEffect(() => {
		if (props.registerError !== null) {
			if (typeof props.registerError === 'string') {
				setErrorText(props.registerError);
			} else {
				setErrorText(props.registerError.message);
			}
		}
	}, [props.registerError, errorText]);

	return (
		<Style.Container
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
		>
			<Style.Heading>Get Started</Style.Heading>
			<Style.RegisterForm
				onSubmit={methods.handleSubmit(onRegisterStart)}
			>
				<Style.InputFieldsContainer>
					<Style.InputField>
						<Style.InputFieldLabel>Email</Style.InputFieldLabel>
						<Style.InputFieldInput
							{...methods.register(`email`)}
							type='email'
							placeholder='Email'
							style={
								methods.formState.errors.email && {
									border: '2px solid red',
									padding: '7px',
								}
							}
						/>
						<ErrorMessage
							errors={methods.formState.errors}
							name='email'
							render={({ message }) => (
								<Style.Error>{message}</Style.Error>
							)}
						/>
					</Style.InputField>
					<Style.InputField>
						<Style.InputFieldLabel>Username</Style.InputFieldLabel>
						<Style.InputFieldInput
							{...methods.register(`username`)}
							type='text'
							placeholder='Username'
							style={
								methods.formState.errors.username && {
									border: '2px solid red',
									padding: '7px',
								}
							}
						/>
						<ErrorMessage
							errors={methods.formState.errors}
							name='username'
							render={({ message }) => (
								<Style.Error>{message}</Style.Error>
							)}
						/>
					</Style.InputField>
				</Style.InputFieldsContainer>
				<Style.RegisterButton
					type='submit'
					onClick={methods.handleSubmit(onRegisterStart)}
				>
					{props.isProcessingRegister ? (
						<CircularProgress size={24} style={{ color: '#FFF' }} />
					) : (
						'Create New Account'
					)}
				</Style.RegisterButton>
				<Style.Links>
					<Style.Link onClick={props.handleToggle}>
						Already Have an Account?
					</Style.Link>
				</Style.Links>
				{errorText && <Style.ErrorText>{errorText}</Style.ErrorText>}
			</Style.RegisterForm>
		</Style.Container>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		unverifiedUserRegisterStart: (
			newUnverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>
		) => dispatch(unverifiedUserRegisterStart(newUnverifiedUserData)),
	};
};

const mapStateToProps = createStructuredSelector({
	isProcessingRegister: selectIsProcessingRegister,
	registerError: selectRegisterError,
	unverifiedRegisteredUser: selectUnverifiedRegisteredUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStart);
