import { ErrorMessage } from '@hookform/error-message';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Style from './forgot-password-form.styles';
import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ForgotPasswordEmailSchema } from '../../../schemas/user/manage-self.schemas';
import { ForgotPasswordOutputSchema } from '../../../schemas/user/authentication.schemas';
import {
	sendForgetPasswordEmailStart,
	UserActionInterface,
} from '../../../redux/user/user.actions';
import {
	selectForgotPasswordEmailError,
	selectForgotPasswordEmailResponseData,
	selectIsSendingForgotPasswordEmail,
} from '../../../redux/user/user.selectors';
import { ArrowBackOutlined, LockOutlined } from '@mui/icons-material';
import ForgotPasswordEmailSent from '../forgot-password-email-sent/forgot-password-email-sent.component';

interface ForgotPasswordProps {
	// PROPS
	// Handle Toggle Event for swiching b/w login/forgot password
	handleShowForgotPasswordForm: () => void;

	// REDUX PROPS
	isSendingForgotPasswordEmail: boolean;
	sendForgotPasswordEmailError: AxiosError | string | null;
	selectForgotPasswordEmailResponseData: z.infer<
		typeof ForgotPasswordOutputSchema
	> | null;

	// REDUX ACTIONS
	sendForgetPasswordEmailStart: (
		email: z.infer<typeof ForgotPasswordEmailSchema>
	) => UserActionInterface;
}

type forgotPasswordEmailSchema = z.infer<typeof ForgotPasswordEmailSchema>;

const ForgotPasswordForm = (props: ForgotPasswordProps) => {
	// ====================== USEFORM ONSUBMIT HANDLING ======================
	const methods = useForm<forgotPasswordEmailSchema>({
		resolver: zodResolver(ForgotPasswordEmailSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	const handleFormSubmit: SubmitHandler<forgotPasswordEmailSchema> = (
		data
	) => {
		props.sendForgetPasswordEmailStart(data);
	};

	// Error handling
	const [errorText, setErrorText] = React.useState<string>('');
	useEffect(() => {
		if (props.sendForgotPasswordEmailError !== null) {
			if (typeof props.sendForgotPasswordEmailError === 'string') {
				setErrorText(props.sendForgotPasswordEmailError);
			} else if (axios.isAxiosError(props.sendForgotPasswordEmailError)) {
				setErrorText(
					props.sendForgotPasswordEmailError.response?.data.detail
				);
			}
		} else {
			setErrorText('');
		}
	}, [props.sendForgotPasswordEmailError, errorText]);

	// State for viewing email confirmation page
	const [showEmailConfirmationPage, setShowEmailConfirmationPage] =
		useState(false);

	useEffect(() => {
		if (props.selectForgotPasswordEmailResponseData !== null) {
			setShowEmailConfirmationPage(true);
		}
	}, [
		showEmailConfirmationPage,
		props.selectForgotPasswordEmailResponseData,
	]);

	return (
		<Style.MainContainer>
			{!showEmailConfirmationPage && (
				<Style.Container
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.6 }}
				>
					<LockOutlined style={{ fontSize: '5rem' }} />
					<Style.Heading>Forgot Password?</Style.Heading>
					<Style.SubHeading>
						Enter your email below and we&apos;ll send you
						instructions for creating a new password.
					</Style.SubHeading>
					<Style.Form
						onSubmit={methods.handleSubmit(handleFormSubmit)}
					>
						<Style.InputField>
							<Style.InputFieldLabel>Email</Style.InputFieldLabel>
							<Style.InputFieldInput
								type='text'
								placeholder='Email'
								{...methods.register(`email`)}
								style={
									methods.formState.errors.email && {
										border: '2px solid red',
										padding: '7px',
									}
								}
							/>
							<ErrorMessage
								errors={methods.formState.errors}
								name='email'
								render={({ message }) => (
									<Style.Error>{message}</Style.Error>
								)}
							/>
						</Style.InputField>
						<Style.MuiButton
							type='submit'
							onClick={methods.handleSubmit(handleFormSubmit)}
						>
							{props.isSendingForgotPasswordEmail ? (
								<CircularProgress
									size={24}
									style={{ color: '#FFF' }}
								/>
							) : (
								'Reset Password'
							)}
						</Style.MuiButton>
						{errorText !== null && (
							<Style.ErrorText>{errorText}</Style.ErrorText>
						)}
						<Style.Links>
							<Style.Link
								onClick={props.handleShowForgotPasswordForm}
							>
								<ArrowBackOutlined /> Back to Login
							</Style.Link>
						</Style.Links>
					</Style.Form>
				</Style.Container>
			)}
			{showEmailConfirmationPage && (
				<ForgotPasswordEmailSent
					email={methods.getValues(`email`)}
					updateEmailRequest={props.sendForgetPasswordEmailStart}
					handleShowForgotPasswordForm={
						props.handleShowForgotPasswordForm
					}
				/>
			)}
		</Style.MainContainer>
	);
};

const mapStateToProps = createStructuredSelector({
	isSendingForgotPasswordEmail: selectIsSendingForgotPasswordEmail,
	sendForgotPasswordEmailError: selectForgotPasswordEmailError,
	selectForgotPasswordEmailResponseData:
		selectForgotPasswordEmailResponseData,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	sendForgetPasswordEmailStart: (email: forgotPasswordEmailSchema) =>
		dispatch(sendForgetPasswordEmailStart(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
