import {
	legacy_createStore as createStore,
	applyMiddleware,
	Middleware,
} from 'redux';

// Import middlewares
import logger from 'redux-logger';
import createSagaMiddleware from '@redux-saga/core';

// Import root saga
import rootSaga from './root.saga';

// Import root reducer
import rootReducer from './root.reducers';

// set the sage middleware
const sagaMiddleware = createSagaMiddleware();

// Define all the redux middlewares
const middlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV == 'development') {
	middlewares.push(logger);
}

// Create Store
export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export default store;
