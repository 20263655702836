import { ForgotPasswordOutputSchema } from './../../schemas/user/authentication.schemas';
import {
	ForgotPasswordEmailSchema,
	GenericResponseSchema,
	PersonalProfileChangePasswordSchema,
	PersonalProfileOutputSchema,
	PersonalProfileResetPasswordInputSchema,
} from './../../schemas/user/manage-self.schemas';
import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { z } from 'zod';
import {
	LoginResponseSchema,
	LogoutResponseSchema,
} from '../../schemas/user/authentication.schemas';
import { UserOutputSchema } from '../../schemas/user/manage-users.schemas';
import {
	UnverifiedUserCreateSchema,
	UnverifiedUserOutputSchema,
	UnverifiedUserVerificationSchema,
	UserOnboardingVerificationResponseSchema,
} from '../../schemas/user/new-user.schemas';
import UserActionTypes from './user.types';

export interface UserActionInterface extends AnyAction {
	type: UserActionTypes;
	payload: unknown;
}

// ============= USER LOGIN PROCESS ===================
/**
 * Redux Action function that **STARTS** the **LOGIN**
 * Process.
 *
 * @param username The username of the user.
 * @param password The password of the user.
 *
 * @returns A Payload that contains both the `username` and `password`
 */
export function userLoginStart(
	username: string,
	password: string
): UserActionInterface {
	return {
		type: UserActionTypes.LOGIN_START,
		payload: {
			username: username,
			password: password,
		},
	};
}

/**
 * Redux Action function which is called upon **SUCCESSFUL** **LOGIN** Process.
 *
 * @param resourceToken The access and refresh token to access the backend resources
 * based on RBAC.
 * @param currentUser The meta details of the current user.
 *
 * @returns A payload with both the `resourceTokens` and `currentUser`.
 */
export function userLoginSuccess(
	resourceTokens: z.infer<typeof LoginResponseSchema>,
	currentUser: z.infer<typeof UserOutputSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.LOGIN_SUCCESS,
		payload: {
			resourceTokens: resourceTokens,
			currentUser: currentUser,
		},
	};
}

/**
 * Redux Action function that is called when **LOGIN** Process
 * failed.
 *
 * @param error The Error received on Failure. Can be both Axios or string
 *
 * @returns A payload with `error`.
 */
export function userLoginFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.LOGIN_FAILURE,
		payload: error,
	};
}

// ===================== REGISTER PROCESS ===================
/**
 * Redux Action function to **START** the **USER REGISTRATION**
 * Process.
 *
 * @param newUnverifiedUserData The new user data obtained from the
 * register form. This should contain `username` and `email` fields.
 *
 * @returns The `newUserData` as payload.
 */
export function unverifiedUserRegisterStart(
	newUnverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.REGISTER_START,
		payload: newUnverifiedUserData,
	};
}

/**
 * Redux Action function called on a **SUCCESSFUL** **USER REGISTRATION**
 * Process.
 *
 * @param unverifiedRegisteredUser The meta data of the new user created
 * by the registration process. Consists of `username`, `email`,  `created_on`, `expires_on`,
 * and `user_id`.
 *
 * @returns The payload which contains the `registeredUser`.
 */
export function unverifiedUserRegisterSuccess(
	unverifiedRegisteredUser: z.infer<typeof UnverifiedUserOutputSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.REGISTER_SUCCESS,
		payload: unverifiedRegisteredUser,
	};
}

/**
 * Redux Action function called on a **FAILED** **USER REGISTRATION**
 * Process.
 *
 * @param error The error received on failure. Can be both Axios or string.
 *
 * @returns The payload which contains the `error`.
 */
export function unverifiedUserRegisterFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.REGISTER_FAILURE,
		payload: error,
	};
}

// ================= VERIFY USER PROCESS ===================
/**
 * Redux Action function to **START** the **USER VERIFICATION**
 * Process.
 *
 * @param unverifiedUserData The new user data obtained from the
 * register form. This should contain `username`, `email`and `OTP` fields.
 *
 * @returns The `userVerificationData` as payload.
 */

export function userVerificationStart(
	userId: string,
	userVerificationData: z.infer<typeof UnverifiedUserVerificationSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.USER_VERIFY_START,
		payload: { userId, userVerificationData },
	};
}

/**
 * Redux Action function called on a **SUCCESSFUL** **USER VERIFICATION**
 * Process.
 *
 * @param verifiedUser The meta data of the new user created
 * by the registration process. Consists of `username`, `email`, `roles`, `random_password`,
 * and `user_id`.
 *
 * @returns The payload which contains the `verifiedUser`.
 */
export function userVerificationSuccess(
	verifiedUser: z.infer<typeof UserOnboardingVerificationResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.USER_VERIFY_SUCCESS,
		payload: verifiedUser,
	};
}

/**
 * Redux Action function called on a **FAILED** **USER VERIFICATION**
 * Process.
 *
 * @param error The error received on failure. Can be both Axios or string.
 *
 * @returns The payload which contains the `error`.
 */
export function userVerificationFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.USER_VERIFY_FAILURE,
		payload: error,
	};
}

// ================== UPDATE UNVERIFIED EMAIL PROCESS ======================
/**
 * Redux Action function to **START** the **UPDATE UNVERIFIED EMAIL**
 * Process.
 *
 * @param unverifiedUserData The new user data obtained from the
 * register form. This should contain `username` and `email`
 *
 * @returns The `unverifiedUserData` as payload.
 */
export function unverifiedUserUpdateEmailStart(
	userId: string,
	unverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.UPDATE_UNVERIFIED_EMAIL_START,
		payload: {
			userId,
			unverifiedUserData,
		},
	};
}

/**
 * Redux Action function called on a **SUCCESSFUL** **UPDATE UNVERIFIED EMAIL**
 * Process.
 *
 * @param unverifiedRegisteredUser The meta data of the new user created
 * by the registration process. Consists of `username`, `email`,  `created_on`, `expires_on`, and `user_id`.
 *
 * @returns The payload which contains the `unverifiedRegisteredUser`.
 */
export function unverifiedUserUpdateEmailSuccess(
	updatedUnverifiedRegisteredUser: z.infer<typeof UnverifiedUserOutputSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.UPDATE_UNVERIFIED_EMAIL_SUCCESS,
		payload: updatedUnverifiedRegisteredUser,
	};
}

/**
 * Redux Action function called on a **FAILED** **UPDATE UNVERIFIED EMAIL**
 * Process.
 *
 * @param error The error received on failure. Can be both Axios or string.
 *
 * @returns The payload which contains the `error`.
 */
export function unverifiedUserUpdateEmailFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.UPDATE_UNVERIFIED_EMAIL_FAILURE,
		payload: error,
	};
}

// ================== CHANGE VERIFIED USER PASSWORD PROCESS ======================
/**
 * Redux Action function to **START** the **CHANGE VERIFIED USER PASSWORD**
 * Process.
 *
 * @param changePasswordData The password data obtained from the
 * password setup form. This should contain `old_password`, `new_password` and `confirm_new_password`.
 */
export function changeVerifiedUserPasswordStart(
	changePasswordData: z.infer<typeof PersonalProfileChangePasswordSchema>,
	resourceTokens: z.infer<typeof LoginResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_START,
		payload: {
			changePasswordData,
			resourceTokens,
		},
	};
}

/*
 * Redux Action function called on a **SUCCESSFUL** **CHANGE VERIFIED USER PASSWORD**
 * Process.
 *
 * @param changeVerifiedUserPasswordResponse The response from the change password API.
 *
 * @returns The payload which contains the `changeVerifiedUserPasswordResponse`.
 */
export function changeVerifiedUserPasswordSuccess(
	changeVerifiedUserPasswordResponse: z.infer<
		typeof PersonalProfileOutputSchema
	>
): UserActionInterface {
	return {
		type: UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_SUCCESS,
		payload: changeVerifiedUserPasswordResponse,
	};
}

/**
 * Redux Action function called on a **FAILED** **CHANGE VERIFIED USER PASSWORD**
 * Process.
 *
 * @param error The error received on failure. Can be both Axios or string.
 *
 * @returns The payload which contains the `error`.
 */
export function changeVerifiedUserPasswordFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_FAILURE,
		payload: error,
	};
}

// ================= SEND FORGET PASSWORD EMAIL PROCESS ======================
/**
 * Redux Action function to **START** the **SEND FORGET PASSWORD EMAIL**
 * Process.
 *
 * @param email The email required to send reset password link.
 *
 * @returns The `email` as payload.
 */
export function sendForgetPasswordEmailStart(
	email: z.infer<typeof ForgotPasswordEmailSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_START,
		payload: email,
	};
}

/**
 * Redux Action function called on a **SUCCESSFUL** **SEND FORGET PASSWORD EMAIL**
 * Process.
 *
 * @param sendForgetPasswordEmailResponse The response from the send forget password email API.
 *
 * @returns The payload which contains the `sendForgetPasswordEmailResponse`.
 */
export function sendForgetPasswordEmailSuccess(
	sendForgetPasswordEmailResponse: z.infer<typeof ForgotPasswordOutputSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
		payload: sendForgetPasswordEmailResponse,
	};
}

/**
 * Redux Action function called on a **FAILED** **SEND FORGET PASSWORD EMAIL**
 * Process.
 *
 * @param error The error received on failure. Can be both Axios or string.
 *
 * @returns The payload which contains the `error`.
 */
export function sendForgetPasswordEmailFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
		payload: error,
	};
}

// ================== FORGOT PASSWORD CHANGE PROCESS ======================
/**
 * Redux Action function to **START** the **FORGOT PASSWORD CHANGE**
 * Process.
 *
 * @param email The email required to send reset password link.
 *
 * @returns The `email` as payload.
 */
export function forgotPasswordChangeStart(
	verificationCode: string,
	resetPasswordData: z.infer<typeof PersonalProfileResetPasswordInputSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.FORGOT_PASSWORD_CHANGE_START,
		payload: {
			verificationCode,
			resetPasswordData,
		},
	};
}

/**
 * Redux Action function called on a **SUCCESSFUL** **FORGOT PASSWORD CHANGE**
 * Process.
 *
 * @param forgotPasswordChangeResponse The response from the forgot password change API.
 *
 * @returns The payload which contains the `forgotPasswordChangeResponse`.
 */
export function forgotPasswordChangeSuccess(
	forgotPasswordChangeResponse: z.infer<typeof GenericResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.FORGOT_PASSWORD_CHANGE_SUCCESS,
		payload: forgotPasswordChangeResponse,
	};
}

/**
 * Redux Action function called on a **FAILED** **FORGOT PASSWORD CHANGE**
 * Process.
 *
 * @param error The error received on failure. Can be both Axios or string.
 *
 * @returns The payload which contains the `error`.
 */
export function forgotPasswordChangeFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.FORGOT_PASSWORD_CHANGE_FAILURE,
		payload: error,
	};
}

// ================== USER REFRESH TOKEN PROCESS ======================
/**
 * Redux Action function to **START** the **USER REFRESH TOKEN**
 * Process.
 *
/**
 * Redux Action function called to **START** the **USER REFRESH TOKEN**
 *
 * @param resourceTokens The resource tokens which includes both
 * `access_token` and `refresh_token`.
 *
 * @returns The payload with `resourceToken`.
 */
export function userRefreshTokenStart(
	resourceTokens: z.infer<typeof LoginResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.USER_REFRESH_TOKEN_START,
		payload: resourceTokens,
	};
}

/**
 * Redux Action function called upon a **SUCCESSFUL** **USER REFRESH TOKEN**
 *
 * @param resourceTokens The resource tokens which includes both
 * `access_token` and `refresh_token`.
 *
 * @returns The payload with `resourceToken`.
 */
export function userRefreshTokenSuccess(
	resourceTokens: z.infer<typeof LoginResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.USER_REFRESH_TOKEN_SUCCESS,
		payload: resourceTokens,
	};
}

/**
 * Redux Action function called when there is a **ERROR**
 * in **USER REFRESH TOKEN**
 *
 * @param error The error caught during failure of refresh token.
 * @returns The payload with the `error`.
 */
export function userRefreshTokenFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.USER_REFRESH_TOKEN_FAILURE,
		payload: error,
	};
}

// ================== LOGOUT PROCESS ======================
/**
 * Redux Action function called to **START** the **USER LOGOUT**
 * Process.
 *
 * @param resourceToken The resource tokens which includes both
 * `access_token` and `refresh_token`.
 *
 * @returns The payload with `resourceTokens`.
 */
export function userLogoutStart(
	resourceTokens: z.infer<typeof LoginResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.USER_LOGOUT_START,
		payload: resourceTokens,
	};
}

/**
 * Redux Action function called upon a **SUCCESSFUL**
 * **USER LOGOUT** Process.
 *
 * @returns A The payload with logout data including `is_logged_out` boolean, `logout_issuing_authority`, and `logged_out_at` timestamp.
 */
export function userLogoutSuccess(
	logoutResponseData: z.infer<typeof LogoutResponseSchema>
): UserActionInterface {
	return {
		type: UserActionTypes.USER_LOGOUT_SUCCESS,
		payload: logoutResponseData,
	};
}

/**
 * Redux Action function called when there is a **ERROR**
 * in **USER LOGOUT** Process.
 *
 * @param error The error caught during failure of logout.
 * @returns The payload with the `error`.
 */
export function userLogoutFailure(
	error: AxiosError | string
): UserActionInterface {
	return {
		type: UserActionTypes.USER_LOGOUT_FAILURE,
		payload: error,
	};
}
