import { combineReducers } from 'redux';

// Import state types
import userReducer, { UserReducerState } from './user/user.reducer';

export interface RootReducerState {
	user: UserReducerState;
}

const rootReducer = combineReducers({
	user: userReducer,
});

export default rootReducer;
