import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { z } from 'zod';
import { selectResourceTokens } from '../../../redux/user/user.selectors';
import { LoginResponseSchema } from '../../../schemas/user/authentication.schemas';
import * as Style from './timeline.styles';

interface TimeLineProps {
	activeStep: number;

	// REDUX PROPS
	resourceTokens: z.infer<typeof LoginResponseSchema> | null;
}

const TimeLine = (props: TimeLineProps) => {
	// Set steps to completed
	const [completed, setCompleted] = useState<boolean[]>([
		false,
		false,
		false,
	]);
	// Watch for changes in activeStep
	useEffect(() => {
		if (props.activeStep === 1) {
			setCompleted([true, false, false]);
		}
		if (props.activeStep === 2) {
			setCompleted([true, true, false]);
		}
		if (props.activeStep === 3) {
			setCompleted([true, true, true]);
		}
	}, [props.activeStep]);

	return (
		<Style.TimelineContainer>
			<Style.Container>
				<Style.CircleLineContainer>
					<Style.TimelineCircle
						style={
							completed[0] && completed[1] === true
								? {
										backgroundColor: ' #06404B',
								  }
								: completed[0] === true
								? {
										backgroundColor: ' #00789A',
								  }
								: {
										background: '#FFF',
										color: '#6F7782',
								  }
						}
						whileInView={
							completed[0] === true
								? { scale: [1.2, 1.2] }
								: { scale: [1.2, 1] }
						}
						transition={{ duration: 0.4, delay: 0.5 }}
					>
						<Style.TimelineNumber
							style={{
								color:
									completed[0] === true ? '#FFF' : '#B2B6C0',
								transition: 'all 0.4s ease-in-out',
							}}
						>
							1
						</Style.TimelineNumber>
					</Style.TimelineCircle>
					<Style.TimelineLine
						color={completed[1] === true ? '#06404B' : ''}
					/>
				</Style.CircleLineContainer>
				<Style.TimelineLabel
					style={{
						color: completed[0] === true ? '#03282E' : '#B2B6C0',
						transition: 'all 0.4s ease-in-out',
					}}
				>
					Create New Account
				</Style.TimelineLabel>
			</Style.Container>

			<Style.Container>
				<Style.CircleLineContainer>
					<Style.TimelineCircle
						style={
							completed[1] === true && completed[2] === true
								? {
										backgroundColor: ' #06404B',
								  }
								: completed[1] === true
								? {
										backgroundColor: ' #00789A',
								  }
								: {
										background: '#FFF',
										color: '#6F7782',
								  }
						}
						whileInView={
							completed[1] === true
								? { scale: [1, 1.2] }
								: { scale: [1.2, 1] }
						}
						transition={{ duration: 0.4 }}
					>
						<Style.TimelineNumber
							style={{
								color:
									completed[1] === true ? '#FFF' : '#B2B6C0',
								transition: 'all 0.4s ease-in-out',
							}}
						>
							2
						</Style.TimelineNumber>
					</Style.TimelineCircle>
					<Style.TimelineLine
						color={completed[2] === true ? '#06404B' : ''}
					/>
				</Style.CircleLineContainer>
				<Style.TimelineLabel
					style={{
						color: completed[1] === true ? '#03282E' : '#B2B6C0',
						transition: 'all 0.4s ease-in-out',
					}}
				>
					Account Verification
				</Style.TimelineLabel>
			</Style.Container>

			<Style.Container>
				<Style.CircleLineContainer>
					<Style.TimelineCircle
						style={
							completed[2] === true
								? {
										backgroundColor: ' #00789A',
								  }
								: props.resourceTokens !== null
								? {
										backgroundColor: ' #06404B',
								  }
								: {
										background: '#FFF',
										color: '#6F7782',
								  }
						}
						whileInView={
							completed[2] === true
								? { scale: [1, 1.2] }
								: { scale: [1.2, 1] }
						}
						transition={{ duration: 0.4 }}
					>
						<Style.TimelineNumber
							style={{
								color:
									completed[2] === true ? '#FFF' : '#B2B6C0',
								transition: 'all 0.4s ease-in-out',
							}}
						>
							3
						</Style.TimelineNumber>
					</Style.TimelineCircle>
				</Style.CircleLineContainer>
				<Style.TimelineLabel
					style={{
						color: completed[2] === true ? '#03282E' : '#B2B6C0',
						transition: 'all 0.4s ease-in-out',
					}}
				>
					Password Setup
				</Style.TimelineLabel>
			</Style.Container>
		</Style.TimelineContainer>
	);
};

const mapStateToProps = createStructuredSelector({
	resourceTokens: selectResourceTokens,
});

export default connect(mapStateToProps)(TimeLine);
