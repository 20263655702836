import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
	width: 2.75rem;
`;

const StyledSVG = styled.svg`
	.cls-1 {
		fill: #3f3f3f;
	}

	.cls-2 {
		fill: #10b1dd;
	}
`;

interface LogoOnlyNurishdInterface {
	className?: string;
}

const LogoOnlyNurishd = ({ className }: LogoOnlyNurishdInterface) => {
	return (
		<LogoContainer className={className}>
			<StyledSVG
				id='Layer_2'
				data-name='Layer 2'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 41.04 31'
			>
				<g id='U'>
					<path
						className='cls-1'
						d='m40.32,1.42c-.48-.53-1.09-.8-1.83-.8s-1.3.27-1.78.8c-.48.53-.72,1.18-.72,1.94v15.16c0,2.47-.57,4.33-1.7,5.59-1.13,1.25-2.78,1.88-4.94,1.88-.8,0-2.81.06-4.34-1.36-.46-.43-.76-.87-.94-1.2l-4.43,2.65c.34.59.71,1.15,1.16,1.64,2.02,2.19,4.87,3.28,8.55,3.28s6.59-1.09,8.63-3.28c2.04-2.18,3.05-5.25,3.05-9.21V3.36c0-.76-.24-1.41-.72-1.94Z'
					/>
					<path
						className='cls-2'
						d='m17.26,7.01c.96,1.33,1.43,3.12,1.43,5.36,0,3.49,0,6.98.01,10.47,1.68-1.02,3.35-2.04,5.03-3.06,0-2.49,0-4.98,0-7.47C23.74,4.1,20.55,0,14.18,0c-1.88,0-3.64.52-5.28,1.57-1.65,1.04-2.93,2.33-3.85,3.85v-1.99c0-.76-.24-1.41-.72-1.94-.48-.53-1.09-.8-1.83-.8s-1.3.27-1.78.8c-.48.53-.72,1.18-.72,1.94v24.4c0,.8.23,1.45.69,1.97.46.51,1.06.77,1.81.77s1.35-.27,1.83-.8c.48-.53.72-1.18.72-1.94v-15.45c0-1.33.33-2.56.98-3.68.65-1.12,1.56-2.01,2.71-2.68,1.15-.66,2.43-1,3.85-1,2.16,0,3.72.67,4.67,2Z'
					/>
				</g>
			</StyledSVG>
		</LogoContainer>
	);
};

export default LogoOnlyNurishd;
