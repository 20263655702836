import React, {
	useRef,
	useState,
	SetStateAction,
	Dispatch as ReactDispatch,
	useEffect,
} from 'react';
import { CircularProgress } from '@mui/material';
import { Dispatch } from 'redux';
import * as Style from './register-verification.styles';
import {
	MarkEmailRead,
	Edit,
	EditOff,
	ForwardToInbox,
} from '@mui/icons-material';
import {
	UnverifiedUserCreateSchema,
	UnverifiedUserOutputSchema,
	UnverifiedUserVerificationSchema,
	UserOnboardingVerificationResponseSchema,
} from '../../../schemas/user/new-user.schemas';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	selectIsProcessingVerifyUser,
	selectIsUpdatingUnverifiedEmail,
	selectUnverifiedRegisteredUser,
	selectUpdatedUnverifiedUser,
	selectUpdateUnverifiedEmailError,
	selectVerifiedUser,
	selectVerifyUserError,
} from '../../../redux/user/user.selectors';
import { z } from 'zod';
import {
	unverifiedUserUpdateEmailStart,
	UserActionInterface,
	userVerificationStart,
} from '../../../redux/user/user.actions';
import axios, { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

interface RegisterVerificationProps {
	// ============= PROPS =============
	// Set the active step of the register process to 3 upon successful register verification
	setActiveStep: ReactDispatch<SetStateAction<number>>;
	// ============ REDUX PROPS ============
	unverifiedRegisteredUser: z.infer<typeof UnverifiedUserOutputSchema> | null;
	verifiedUser: z.infer<
		typeof UserOnboardingVerificationResponseSchema
	> | null;
	isProcessingVerifyUser: boolean;
	verifyUserError: AxiosError | string | null;

	// Update the unverified user's email
	isUpdatingUnverifiedEmail: boolean;
	updateUnverifiedEmailError: AxiosError | string | null;
	updatedUnverifiedUser: z.infer<typeof UnverifiedUserOutputSchema> | null;

	// ============ REDUX ACTIONS ============
	userVerificationStart: (
		userId: string,
		userVerificationData: z.infer<typeof UnverifiedUserVerificationSchema>
	) => UserActionInterface;

	unverifiedUserUpdateEmailStart: (
		userId: string,
		unverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>
	) => UserActionInterface;
}

type verificationSchema = z.infer<typeof UnverifiedUserVerificationSchema>;

const RegisterVerification = (props: RegisterVerificationProps) => {
	// ====================== USEFORM ONSUBMIT HANDLING ======================
	const methods = useForm<verificationSchema>({
		resolver: zodResolver(UnverifiedUserVerificationSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	const onVerifySubmit: SubmitHandler<verificationSchema> = (data) => {
		if (props.unverifiedRegisteredUser !== null) {
			props.userVerificationStart(
				props.unverifiedRegisteredUser.user_id as string,
				data as z.infer<typeof UnverifiedUserVerificationSchema>
			);
		}
	};

	useEffect(() => {
		// If the verification process is successful, set the active step to 3
		if (props.verifiedUser !== null) {
			props.setActiveStep(3);
		}
	}, [props.verifiedUser]);

	// Set username value to the unverified user's username upon component mount
	useEffect(() => {
		if (props.unverifiedRegisteredUser !== null) {
			methods.setValue(
				`username`,
				props.unverifiedRegisteredUser.username
			);
		}
	}, []);

	// ====================== EMAIL HANDLING ======================
	const [email, setEmail] = React.useState(methods.watch(`email`));

	useEffect(() => {
		if (props.unverifiedRegisteredUser !== null) {
			setEmail(props.unverifiedRegisteredUser.email);
			methods.setValue(`email`, props.unverifiedRegisteredUser.email);
		} else {
			setEmail(methods.watch(`email`));
			methods.setValue(`email`, methods.watch(`email`));
		}
	}, [props.unverifiedRegisteredUser, email]);

	const [disableEmail, setDisableEmail] = React.useState(true);

	const handleDisableEmail = () => {
		setDisableEmail(!disableEmail);
	};

	// Update the unverified user's email
	const handleUpdateEmail = () => {
		if (props.unverifiedRegisteredUser !== null) {
			props.unverifiedUserUpdateEmailStart(
				props.unverifiedRegisteredUser.user_id as string,
				{
					username: props.unverifiedRegisteredUser.username,
					email: methods.watch(`email`),
				} as z.infer<typeof UnverifiedUserCreateSchema>
			);
		}
		setDisableEmail(true);
	};

	// ====================== EMAIL HANDLING ======================

	// ====================== ERROR HANDLING ======================
	const [errorText, setErrorText] = useState<string | null>(null);

	// ====================== OTP HANDLING ======================
	const [otp, setOTP] = useState<string[]>(Array(8).fill(''));
	const inputRefs = useRef<HTMLInputElement[]>(Array(8).fill(null));

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const value = event.target.value;
		if (isNaN(Number(value))) {
			return;
		}
		const newOTP = [...otp];
		newOTP[index] = value;
		setOTP(newOTP);

		if (value !== '' && index < 7) {
			inputRefs.current[index + 1].focus();
		}
	};

	useEffect(() => {
		if (otp.join('').length === 8) {
			inputRefs.current[7].blur();
			methods.setValue(`otp_code`, otp.join(''));
		}
	}, [otp]);

	// Handle the user's pasting of OTP code and easily delete the OTP code
	// Allow user to copy and paste OTP code from clipboard to input fields
	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const pastedData = event.clipboardData.getData('text');
		const pastedOTP = pastedData.slice(0, 8);
		const newOTP = Array.from(pastedOTP, (digit) =>
			isNaN(Number(digit)) ? '' : digit
		);
		setOTP(newOTP);
	};

	// Allow user to use backspace to delete OTP code
	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>,
		index: number
	) => {
		if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
			inputRefs.current[index - 1].focus();
		}
	};

	// ====================== OTP HANDLING ======================

	// Handle Click Event for opening email app
	const handleOpenEmailApp = () => {
		window.location.href = `mailto:`;
	};

	// Handle Errors
	useEffect(() => {
		const fullOTP = otp.join('');
		if (fullOTP.length === 0) {
			return;
		}
		if (fullOTP.length < 8) {
			setErrorText('Please fill in all digit fields.');
		}
		if (fullOTP.length === 8) {
			setErrorText(null);
		}

		if (props.verifyUserError !== null) {
			if (typeof props.verifyUserError === 'string') {
				setErrorText(props.verifyUserError);
			} else if (axios.isAxiosError(props.verifyUserError)) {
				const errorData = props.verifyUserError.response?.data as {
					detail?: string;
				};
				setErrorText(errorData.detail ?? JSON.stringify(errorData));
			}
		} else {
			setErrorText('');
		}
	}, [props.verifyUserError, errorText]);

	return (
		<Style.Container
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
		>
			<MarkEmailRead className='emailIcon' />
			<Style.Heading>A verification code was sent!</Style.Heading>
			<Style.VerifyForm onSubmit={methods.handleSubmit(onVerifySubmit)}>
				<Style.InputFieldsContainer
					style={{
						borderColor: disableEmail
							? '#eaf2f5'
							: 'rgba(0, 64, 75, 0.74)',
					}}
				>
					<Style.InputField>
						<Style.InputFieldInput
							type='email'
							placeholder='Email'
							disabled={disableEmail}
							defaultValue={email}
							style={{ color: disableEmail ? '#878c91' : '#000' }}
							{...methods.register('email')}
						/>
						<Style.EditButton onClick={handleDisableEmail}>
							{disableEmail ? (
								<Edit className='editIcon' />
							) : (
								<EditOff className='editIcon' />
							)}
						</Style.EditButton>
					</Style.InputField>
				</Style.InputFieldsContainer>
				<Style.BtnContainer>
					{methods.watch(`email`) !==
						props.unverifiedRegisteredUser?.email && (
						<Style.SendBtn
							type='submit'
							onClick={handleUpdateEmail}
						>
							{props.isUpdatingUnverifiedEmail ? (
								<CircularProgress
									size={20}
									style={{ color: '#FFF' }}
								/>
							) : (
								<Style.ResendContainer>
									<ForwardToInbox className='sendEmailIcon' />
									Request New Code
								</Style.ResendContainer>
							)}
						</Style.SendBtn>
					)}
					<Style.EmailBtn type='button' onClick={handleOpenEmailApp}>
						Open Email App
					</Style.EmailBtn>
				</Style.BtnContainer>
				<Style.CodeContainer>
					{errorText !== null && (
						<Style.ErrorText>{errorText}</Style.ErrorText>
					)}
					<Style.DigitContainer>
						{otp.map((digit, index) => (
							<Style.DigitInput
								{...methods.register('otp_code')}
								key={index}
								type='text'
								maxLength={1}
								value={digit}
								onChange={(event) =>
									handleInputChange(event, index)
								}
								ref={(input) => {
									if (input) {
										inputRefs.current[index] = input;
									}
								}}
								onPaste={handlePaste}
								onKeyDown={(event) =>
									handleKeyDown(event, index)
								}
							/>
						))}
					</Style.DigitContainer>
					<Style.Btn
						type='submit'
						onClick={methods.handleSubmit(onVerifySubmit)}
					>
						{props.isProcessingVerifyUser ? (
							<CircularProgress
								size={24}
								style={{ color: '#FFF' }}
							/>
						) : (
							'Verify'
						)}
					</Style.Btn>
				</Style.CodeContainer>
				<Style.Links>
					<Style.Link onClick={handleUpdateEmail}>
						Didn&apos;t receive the code?{' '}
						<Style.Request>Request Again</Style.Request>
						<Style.Request>
							{props.isUpdatingUnverifiedEmail && (
								<CircularProgress
									size={18}
									style={{
										color: '#10B1DD',
										position: 'absolute',
										marginLeft: '5px',
									}}
								/>
							)}
						</Style.Request>
					</Style.Link>
				</Style.Links>
			</Style.VerifyForm>
		</Style.Container>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		userVerificationStart: (
			userId: string,
			userVerificationData: z.infer<
				typeof UnverifiedUserVerificationSchema
			>
		) => dispatch(userVerificationStart(userId, userVerificationData)),
		unverifiedUserUpdateEmailStart: (
			userId: string,
			unverifiedUserData: z.infer<typeof UnverifiedUserCreateSchema>
		) =>
			dispatch(
				unverifiedUserUpdateEmailStart(userId, unverifiedUserData)
			),
	};
};

const mapStateToProps = createStructuredSelector({
	unverifiedRegisteredUser: selectUnverifiedRegisteredUser,
	verifiedUser: selectVerifiedUser,
	isProcessingVerifyUser: selectIsProcessingVerifyUser,
	verifyUserError: selectVerifyUserError,

	updatedUnverifiedUser: selectUpdatedUnverifiedUser,
	isUpdatingUnverifiedEmail: selectIsUpdatingUnverifiedEmail,
	updateUnverifiedEmailError: selectUpdateUnverifiedEmailError,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegisterVerification);
