import { z } from 'zod';

export const LoginResponseSchema = z.object({
	access_token: z.string({
		description: 'Access Token issued based on RBAC',
	}),
	refresh_token: z.string({
		description: 'Refresh Token to refresh and reboot user session',
	}),
	token_type: z.string().default('bearer'),
});

export const UserInputSchema = z.object({
	username: z
		.string({
			description: 'Username/email of the user',
			required_error: 'Either username or email must be provided',
		})
		.min(1, { message: 'Either username or email must be provided' })
		.min(8, { message: 'Invalid Username or Email' })
		.max(50, { message: 'Invalid Username or Email' }),
	// email: z.string().email({ message: 'Invalid Email' }).optional(),
	password: z
		.string({
			description: 'Password of the user',
			required_error: 'Password is required',
		})
		.min(1, { message: 'Password is required' })
		.min(8, { message: 'Invalid Password' })
		.max(128, { message: 'Invalid Password' })
		.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}/, {
			message: 'Invalid Password',
		}),
});

export const LogoutResponseSchema = z.object({
	is_logged_out: z.boolean({
		description: 'Logged Out Status',
	}),
	logout_issuing_authority: z.string({
		description: 'Logged Out Issuing Authority',
	}),
	logged_out_at: z.coerce.date({
		description: 'Logged Out Time Stamp - UTC Timezone',
	}),
});

export const ForgotPasswordOutputSchema = z.object({
	is_password_reset_link_sent: z.boolean({
		description: 'Password Reset Link Sent Status',
	}),
	password_reset_link_issuing_authority: z.string({
		description: 'Password Reset Link Issuing Authority',
	}),
	password_reset_link_issued_at: z.coerce.date({
		description: 'Password Reset Link Issued Time Stamp - UTC Timezone',
	}),
});

export const AuthorizationResponseSchema = z.object({
	user_id: z.string({ description: 'User Unique ID' }).uuid(),
	is_authorized: z.boolean({ description: 'Authorization Result' }),
	authorization_check_authority: z.string({
		description: 'Authorization Check Issuing Authority',
	}),
	authorization_checked_at: z.coerce.date({
		description: 'Authorization Check Time Stamp - UTC Timezone',
	}),
});
