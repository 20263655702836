import React, { useEffect, useState } from 'react';
import * as Style from './login.styles';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { z } from 'zod';
import { Dispatch } from 'redux';
import {
	LoginResponseSchema,
	UserInputSchema,
} from '../../schemas/user/authentication.schemas';
import {
	UserActionInterface,
	userLoginStart,
} from '../../redux/user/user.actions';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	selectCurrentUser,
	selectResourceTokens,
	selectIsProcessingLogin,
	selectLoginError,
} from '../../redux/user/user.selectors';
import { UserOutputSchema } from '../../schemas/user/manage-users.schemas';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import ForgotPasswordForm from '../forgot-password-process/forgot-password-form/forgot-password-form.component';

interface LoginProps {
	// Handle Toggle Event for swiching b/w login/register
	handleToggle: () => void;
	handleShowForgotPasswordForm: () => void;
	showForgotPasswordForm: boolean;

	// REDUX PROPS
	currentUser: z.infer<typeof UserOutputSchema> | null;
	resourceTokens: z.infer<typeof LoginResponseSchema> | null;
	isProcessingLogin: boolean;
	loginError: AxiosError | string | null;
	// REDUX ACTIONS
	userLoginStart: (username: string, password: string) => UserActionInterface;
}

type loginSchema = z.infer<typeof UserInputSchema>;

const Login = (props: LoginProps) => {
	const navigate = useNavigate();
	// ====================== USEFORM ONSUBMIT HANDLING ======================
	const methods = useForm<loginSchema>({
		resolver: zodResolver(UserInputSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	const onLoginSubmit: SubmitHandler<loginSchema> = (data) => {
		props.userLoginStart(data.username as string, data.password as string);
	};

	// View and hide password
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	// Enter application upon successful login and reset form
	useEffect(() => {
		if (props.resourceTokens !== null) {
			methods.reset();
			navigate('/auth/loading');
		}
	}, [props.resourceTokens]);

	// Error handling
	const [errorText, setErrorText] = React.useState<string>('');
	useEffect(() => {
		if (props.loginError !== null) {
			if (typeof props.loginError === 'string') {
				setErrorText(props.loginError);
			} else {
				setErrorText(props.loginError.message);
			}
		}
	}, [props.loginError, errorText]);

	return (
		<Style.MainContainer>
			{!props.showForgotPasswordForm && (
				<Style.LoginContainer
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.6 }}
				>
					<Style.Heading>Login</Style.Heading>
					<Style.LoginForm
						onSubmit={methods.handleSubmit(onLoginSubmit)}
					>
						<Style.InputField>
							<Style.InputFieldLabel>
								Username or Email
							</Style.InputFieldLabel>
							<Style.InputFieldInput
								type='text'
								placeholder='Username or Email'
								{...methods.register(`username`)}
								style={
									methods.formState.errors.username && {
										border: '2px solid red',
										padding: '7px',
									}
								}
							/>
							{methods.formState.errors.username && (
								<Style.Error>
									{methods.formState.errors.username.message}
								</Style.Error>
							)}
						</Style.InputField>
						<Style.InputField>
							<Style.InputFieldLabel>
								Password
							</Style.InputFieldLabel>
							<Style.InputFieldInput
								type={showPassword ? 'text' : 'password'}
								placeholder='Password'
								{...methods.register(`password`)}
								style={
									methods.formState.errors.password && {
										border: '2px solid red',
										padding: '7px',
									}
								}
							/>
							<Style.InputFieldButton
								onClick={toggleShowPassword}
							>
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</Style.InputFieldButton>
							<ErrorMessage
								errors={methods.formState.errors}
								name='password'
								render={({ message }) => (
									<Style.Error>{message}</Style.Error>
								)}
							/>
						</Style.InputField>
						<Style.LoginButton
							type='submit'
							onClick={methods.handleSubmit(onLoginSubmit)}
						>
							{props.isProcessingLogin ? (
								<CircularProgress
									size={24}
									style={{ color: '#FFF' }}
								/>
							) : (
								'Login'
							)}
						</Style.LoginButton>
						<Style.Links>
							<Style.Link
								onClick={props.handleShowForgotPasswordForm}
							>
								Forgot Password?
							</Style.Link>
							<Style.Link onClick={props.handleToggle}>
								Create a New Account?
							</Style.Link>
						</Style.Links>
					</Style.LoginForm>
					{errorText !== null && (
						<Style.ErrorText>{errorText}</Style.ErrorText>
					)}
				</Style.LoginContainer>
			)}
			{props.showForgotPasswordForm && (
				<ForgotPasswordForm
					handleShowForgotPasswordForm={
						props.handleShowForgotPasswordForm
					}
				/>
			)}
		</Style.MainContainer>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	resourceTokens: selectResourceTokens,
	isProcessingLogin: selectIsProcessingLogin,
	loginError: selectLoginError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	userLoginStart: (username: string, password: string) =>
		dispatch(userLoginStart(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
