import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { z } from 'zod';
import { APP_ENV } from '../../env/app.env';
import { selectResourceTokens } from '../../redux/user/user.selectors';
import { LoginResponseSchema } from '../../schemas/user/authentication.schemas';
import * as Style from './loader.styles';

interface TokenProps {
	resourceTokens: z.infer<typeof LoginResponseSchema> | null;
}

const Loader = (props: TokenProps) => {
	React.useEffect(() => {
		if (props.resourceTokens !== null) {
			localStorage.setItem(
				'tokens',
				JSON.stringify(props.resourceTokens)
			);
			window.location.assign(APP_ENV.MAIN_MICROFRONTEND_URL);
		}
	}, [props.resourceTokens]);

	return (
		<Style.LoaderContainer>
			<div className='lds-ellipsis'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Style.LoaderContainer>
	);
};

const mapStateToProps = createStructuredSelector({
	resourceTokens: selectResourceTokens,
});

export default connect(mapStateToProps)(Loader);
