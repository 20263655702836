import styled from 'styled-components';
import { styled as muistyled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
`;

export const RegisterForm = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: 100%;
	position: relative;
`;

export const Error = styled.p`
	margin: 0;
	padding: 0;
	color: #ff0000;
	font-size: 0.8rem;
	line-height: 1.1;
	max-width: 100%;
	position: absolute;
	bottom: -10px;
`;

export const ErrorText = styled(Error)`
	position: absolute;
	font-size: 0.95rem;
	font-weight: 600;
	bottom: -3rem;
	width: max-content;
	text-align: center;
	max-width: 320px;
`;

export const Heading = styled.h1`
	font-weight: 700;
	font-size: 2.2rem;
	color: #00404b;
	white-space: nowrap;
`;

export const InputFieldsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 500px;
	gap: 1rem;
`;

export const InputField = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	min-width: 210px;
	position: relative;
`;

export const InputFieldLabel = styled.label`
	font-size: 1.1rem;
	font-weight: 600;
	color: #000;
`;

export const InputFieldInput = styled.input`
	box-sizing: border-box;
	width: 100%;

	height: 3rem;
	border: 1px solid #b7c5cf;
	padding: 0.5rem;
	margin: 0.5rem 0;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 10px;
	background-color: #fff;

	::placeholder {
		color: #b7c5cf;
	}

	&:hover {
		border: 1px solid rgba(16, 177, 221, 0.5);
	}

	&:focus {
		outline: none;
		border: 1px solid #10b1dd;
	}
`;

export const Links = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const Link = styled.a`
	font-size: 15px;
	font-weight: 600;
	color: #00404b;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.5s ease;
	white-space: nowrap;
`;

export const RegisterButton = muistyled(Button)({
	minWidth: '255px',
	fontFamily: 'inherit',
	textTransform: 'capitalize',
	fontSize: '1rem',
	fontWeight: 500,
	color: '#fff',
	backgroundColor: '#00404b',
	width: 'fit-content',
	padding: '0 3rem',
	height: '40px',
	borderRadius: '10px',
	margin: '15px 0',
	whiteSpace: 'nowrap',

	'&:hover': {
		backgroundColor: 'rgba(0, 64, 75, 0.74)',
	},
});
