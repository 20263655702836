enum RoleBasedAccessControlEnum {
	ADMIN = 'Admin',
	USER = 'User',
	TRAINEE = 'Trainee',
	MANAGE_SELF_ACCESS = 'Manage Self Access',
	INTERNAL_USERS_USER_ADMIN = 'Internal Users User Admin',
	INTERNAL_USERS_USER_ACCESS = 'Internal Users User Access',
	INTERNAL_USERS_TRAINEE_ACCESS = 'Internal Users Trainee Access',
	INTERNAL_USERS_CREATE_ACCESS = 'Internal Users Create Access',
	INTERNAL_USERS_READ_ACCESS = 'Internal Users Read Access',
	INTERNAL_USERS_EDIT_ACCESS = 'Internal Users Edit Access',
	INTERNAL_USERS_DELETE_ACCESS = 'Internal Users Delete Access',
	CUSTOMER_USER_ADMIN = 'Customer User Admin',
	CUSTOMER_USER_ACCESS = 'Customer User Access',
	CUSTOMER_TRAINEE_ACCESS = 'Customer Trainee Access',
	CUSTOMER_CREATE_ACCESS = 'Customer Create Access',
	CUSTOMER_READ_ACCESS = 'Customer Read Access',
	CUSTOMER_EDIT_ACCESS = 'Customer Edit Access',
	CUSTOMER_DELETE_ACCESS = 'Customer Delete Access',
	ORDER_USER_ADMIN = 'Order User Admin',
	ORDER_USER_ACCESS = 'Order User Access',
	ORDER_TRAINEE_ACCESS = 'Order Trainee Access',
	ORDER_CREATE_ACCESS = 'Order Create Access',
	ORDER_READ_ACCESS = 'Order Read Access',
	ORDER_EDIT_ACCESS = 'Order Edit Access',
	ORDER_DELETE_ACCESS = 'Order Delete Access',
}

export default RoleBasedAccessControlEnum;
