import styled from 'styled-components';
import { styled as muistyled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';

export const MainContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoginContainer = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50%;
	gap: 2rem;
	position: relative;
`;

export const Heading = styled.h1`
	font-weight: 700;
	font-size: 2.2rem;
	color: #000;
	white-space: nowrap;
`;

export const LoginForm = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 1rem;
`;

export const Error = styled.p`
	margin: 0;
	padding: 0;
	color: #ff0000;
	font-size: 0.8rem;
	line-height: 1.1;
	max-width: 100%;
	position: absolute;
	bottom: -8px;
	white-space: nowrap;
`;

export const ErrorText = styled(Error)`
	position: absolute;
	font-size: 0.95rem;
	font-weight: 600;
	bottom: -3rem;
	width: max-content;
	text-align: center;
	max-width: 320px;
`;

export const InputField = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	min-width: 210px;
	position: relative;
`;

export const InputFieldLabel = styled.label`
	font-size: 1.1rem;
	font-weight: 600;
	color: #000;

	@media (max-height: 730px) {
		font-size: 0.9rem;
	}

	@media (max-width: 975px) {
		font-size: 0.9rem;
	}
`;

export const InputFieldInput = styled.input`
	box-sizing: border-box;
	width: 100%;
	height: 3rem;
	border: 1px solid #b7c5cf;
	padding: 0.5rem;
	margin: 0.5rem 0;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 10px;
	background-color: #fff;
	position: relative;
	border: 1px solid #b7c5cf;

	margin-top: 5px;

	::placeholder {
		color: #b7c5cf;
	}

	&:hover {
		border: 1px solid rgba(16, 177, 221, 0.5);
	}

	&:focus {
		outline: none;
		border: 1px solid #10b1dd;
	}

	/* Hide the show/hide icon in Chrome, Edge, and Safari */
	input[type='password']::-ms-reveal,
	input[type='password']::-ms-clear,
	input[type='password']::-webkit-contacts-auto-fill-button {
		color: none;
		font-size: 0px;
		display: none;
	}
	input::-ms-reveal,
	input::-ms-clear,
	::-ms-reveal,
	::-ms-clear {
		display: none;
	}
	input[type='password']::-webkit-contacts-auto-fill-button {
		display: none !important;
	}
`;

export const InputFieldButton = muistyled(Button)({
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-15%)',
	right: '20px',
	background: 'none',
	border: 'none',
	fontSize: '0.9rem',
	color: '#333',
	cursor: 'pointer',
	maxWidth: '30px',
	minWidth: '30px',
	maxHeight: '30px',
	minHeight: '30px',
	padding: '0',

	'@media (max-width: 975px)': {
		transform: 'translateY(-20%)',
	},
});

export const Links = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 1.5rem;
`;

export const Link = styled.p`
	font-size: 15px;
	font-weight: 600;
	color: #00404b;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.5s ease;
	white-space: nowrap;
`;

export const LoginButton = muistyled(Button)({
	fontFamily: 'inherit',
	textTransform: 'capitalize',
	fontSize: '1rem',
	fontWeight: 500,
	color: '#fff',
	backgroundColor: '#00404b',
	width: 'fit-content',
	padding: '0 3rem',
	height: '40px',
	borderRadius: '10px',
	margin: '15px 0',
	minWidth: '138px',

	'&:hover': {
		backgroundColor: 'rgba(0, 64, 75, 0.74)',
	},
});
