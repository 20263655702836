enum UserActionTypes {
	// ======= USER SIGN IN ========
	LOGIN_START = 'LOGIN_START',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	LOGIN_FAILURE = 'LOGIN_FAILURE',

	// ======= USER REGISTER =======
	REGISTER_START = 'REGISTER_START',
	REGISTER_SUCCESS = 'REGISTER_SUCCESS',
	REGISTER_FAILURE = 'REGISTER_FAILURE',

	// ======= USER LOGOUT =========
	USER_LOGOUT_START = 'USER_LOGOUT_START',
	USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
	USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE',

	// ======= USER REFRESH TOKEN =========
	USER_REFRESH_TOKEN_START = 'USER_REFRESH_TOKEN_START',
	USER_REFRESH_TOKEN_SUCCESS = 'USER_REFRESH_TOKEN_SUCCESS',
	USER_REFRESH_TOKEN_FAILURE = 'USER_REFRESH_TOKEN_FAILURE',

	// ======= USER VERIFICATION =========
	USER_VERIFY_START = 'USER_VERIFY_START',
	USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS',
	USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE',

	// ======= UPDATE UNVERIFIED USER EMAIL =========
	UPDATE_UNVERIFIED_EMAIL_START = 'UPDATE_UNVERIFIED_USER_EMAIL_START',
	UPDATE_UNVERIFIED_EMAIL_SUCCESS = 'UPDATE_UNVERIFIED_USER_EMAIL_SUCCESS',
	UPDATE_UNVERIFIED_EMAIL_FAILURE = 'UPDATE_UNVERIFIED_USER_EMAIL_FAILURE',

	// ======= RESET VERIFIED USER PASSWORD =========
	CHANGE_VERIFIED_USER_PASSWORD_START = 'CHANGE_VERIFIED_USER_PASSWORD_START',
	CHANGE_VERIFIED_USER_PASSWORD_SUCCESS = 'CHANGE_VERIFIED_USER_PASSWORD_SUCCESS',
	CHANGE_VERIFIED_USER_PASSWORD_FAILURE = 'CHANGE_VERIFIED_USER_PASSWORD_FAILURE',

	// ======= SEND FORGOT PASSWORD EMAIL =========
	SEND_FORGOT_PASSWORD_EMAIL_START = 'SEND_FORGOT_PASSWORD_EMAIL_START',
	SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS',
	SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'SEND_FORGOT_PASSWORD_EMAIL_FAILURE',

	// ======= FORGOT PASSWORD CHANGE =========
	FORGOT_PASSWORD_CHANGE_START = 'FORGOT_PASSWORD_CHANGE_START',
	FORGOT_PASSWORD_CHANGE_SUCCESS = 'FORGOT_PASSWORD_CHANGE_SUCCESS',
	FORGOT_PASSWORD_CHANGE_FAILURE = 'FORGOT_PASSWORD_CHANGE_FAILURE',
}

export default UserActionTypes;
