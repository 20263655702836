import styled from 'styled-components';
import { styled as muistyled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';

export const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Container = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	position: relative;

	svg.emailIcon {
		font-size: 100px;
		color: #00404b;

		@media (max-width: 975px) {
			font-size: 70px;
		}
	}
`;

export const Heading = styled.h1`
	font-weight: 700;
	font-size: 2.2rem;
	color: #000;
	white-space: nowrap;
	margin: 0;

	@media (max-width: 1094px) {
		font-size: 1.6rem;
	}

	@media (max-width: 975px) {
		font-size: 1.5rem;
	}
`;

export const SubHeading = styled.p`
	width: 100%;
	font-weight: 500;
	font-size: 1.2rem;
	color: #000;
	text-align: center;
	margin: 0 auto;

	@media (max-width: 875px) {
		font-size: 0.9rem;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	width: 100%;
`;

export const InputFieldsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	max-width: 500px;
	min-width: fit-content;
	gap: 5px;
	border: 2px solid #eaf2f5;
	border-radius: 20px;
	padding: 0 1rem;

	@media (max-width: 975px) {
		height: 55px;
	}

	&:focus-within {
		border-color: rgba(0, 64, 75, 0.74);
	}
`;

export const InputField = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	@media (max-width: 1094px) {
		width: 100%;
	}
`;

export const Error = styled.p`
	margin: 0;
	padding: 0;
	color: #ff0000;
	font-size: 0.9rem;
	line-height: 1.1;
	max-width: 100%;
	position: absolute;
	bottom: 3px;
	left: 15px;
`;

export const InputFieldInput = styled.input`
	min-width: 260px;
	box-sizing: border-box;
	width: 100%;

	height: 3rem;
	border: none;
	padding: 0.5rem;
	margin: 0.5rem;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 10px;
	background-color: #fff;
	text-overflow: ellipsis;

	@media (max-width: 1094px) {
		min-width: 220px;
	}

	::placeholder {
		color: #b7c5cf;
	}

	&:hover {
		border: none;
	}

	&:focus {
		outline: none;
		border: none;
	}
`;

export const ErrorText = styled.p`
	font-size: 0.9rem;
	color: #ff0000;
	margin: 0;
	font-weight: 600;
	position: absolute;
	bottom: -2rem;
`;

export const Links = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	gap: 2rem;
`;

export const Link = styled.p`
	font-size: 15px;
	font-weight: 600;
	color: #00404b;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.5s ease;
	white-space: nowrap;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
`;

export const Request = styled.span`
	font-weight: 800;
	display: flex;
	align-items: center;
`;

export const Btn = muistyled(Button)({
	fontFamily: 'inherit',
	textTransform: 'capitalize',
	fontSize: '1rem',
	fontWeight: 500,
	color: '#fff',
	backgroundColor: '#00404b',
	width: 'fit-content',
	padding: '0 3rem',
	height: '40px',
	borderRadius: '10px',
	whiteSpace: 'nowrap',
	minWidth: '145px',
	maxWidth: '145px',

	'&:hover': {
		backgroundColor: 'rgba(0, 64, 75, 0.74)',
	},
});

export const EmailBtn = muistyled(Btn)({
	minWidth: 'fit-content',
	marginTop: '20px',
});

export const EditButton = muistyled(Button)({
	color: '#00404b',
	backgroundColor: 'none',
	maxWidth: '40px',
	maxHeight: '40px',
	minWidth: '40px',
	minHeight: '40px',
	padding: '0',
	borderRadius: '10px',

	'&:hover': {
		backgroundColor: '#EAF2F5',
	},

	'& svg.editIcon': {
		fontSize: '25px',

		'@media (max-width: 1094px)': {
			fontSize: '20px',
		},
	},
});

export const ResendText = styled.p`
	font-size: 0.9rem;
	color: #fff;
	margin: 0;
	font-weight: 500;
`;

export const ResendContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
`;

export const BtnContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	flex-direction: column;
	gap: 1rem;
`;

export const SendBtn = muistyled(Btn)({
	position: 'absolute',
	top: '-17px',
	fontSize: '0.85rem',
	color: '#00404b',
	background: '#EAF2F5',
	padding: '0 2rem',
	height: '28px',
	borderRadius: '5px',
	maxWidth: '175px',

	'&:hover': {
		backgroundColor: 'rgba(0, 64, 75, 0.24)',
	},

	'& svg.sendEmailIcon': {
		fontSize: '20px',

		'@media (max-width: 1094px)': {
			fontSize: '16px',
		},
	},
});
