import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgImage from '../../assets/background.webp';

export const AuthPage = styled.div`
	height: 100%;
`;

export const AuthContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	overflow: hidden;
	background-image: url(${bgImage});
	background-repeat: no-repeat;
	background-position-y: 30%;
	background-position-x: 100%;
	background-position: fixed;
	background-size: 140%;

	@media screen and (max-width: 1000px) {
		background-size: cover;
	}

	::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			39.52deg,
			rgba(173, 212, 216, 0.65) 39.92%,
			rgba(217, 217, 217, 0) 81.46%
		);
	}
`;

export const SecondaryContainer = styled(motion.div)`
	display: flex;
	flex-direction: column;
	margin: 27px 0;
	display: flex;
	background: #fff;
	border-top-left-radius: 25px;
	border-bottom-right-radius: 25px;
	width: 50%;
	min-width: 350px;
	max-width: 1000px;
`;

export const TimeLineContainer = styled(motion.div)`
	position: absolute;
	top: 30%;
	margin-right: 10px;
`;

export const Img = styled.img`
	width: 55px;
	height: auto;
`;

export const FieldInput = styled.input``;

export const Label = styled.label``;

export const Span = styled.span``;

export const SliderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
`;

export const Slider = styled.div`
	.toggle-switch {
		position: relative;
		display: inline-block;
		width: 160px;
		height: 40px;
	}
	.toggle-switch input[type='checkbox'] {
		display: none;
	}

	.toggle-switch .switch {
		position: absolute;
		cursor: pointer;
		background-color: #eaf2f5;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.toggle-switch .switch::before {
		position: absolute;
		content: '';
		width: 80px;
		height: 40px;
		background-color: #00404b;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
		transition: transform 0.6s ease;
	}

	.toggle-switch input[type='checkbox']:checked + .switch::before {
		transform: translateX(80px);
		background-color: #00404b;
	}
	.toggle-switch input[type='checkbox']:checked + .switch {
		background-color: #eaf2f5;
	}

	.label-left {
		position: absolute;
		top: 10px;
		left: 15px;
		font-size: 14px;
		font-weight: 600;
		transition: color 0.5s ease;
	}

	.label-right {
		position: absolute;
		top: 10px;
		right: 24px;
		font-size: 14px;
		font-weight: 600;
		transition: color 0.9s ease;
	}
`;

export const AuthOptionContainer = styled(motion.div)`
	display: flex;
	align-items: center;
	flex-direction: column;
`;
