import { createSelector } from 'reselect';
import { RootReducerState } from '../root.reducers';

const selectUser = (state: RootReducerState) => state.user;

export const selectCurrentUser = createSelector(
	[selectUser],
	(user) => user.currentUser
);

// ==================== LOGIN SELECTORS ===================
export const selectResourceTokens = createSelector(
	[selectUser],
	(user) => user.resourceTokens
);

export const selectIsProcessingLogin = createSelector(
	[selectUser],
	(user) => user.isProcessingLogin
);

export const selectLoginError = createSelector(
	[selectUser],
	(user) => user.loginError
);

// ================= REGISTER SELECTORS ===================
export const selectUnverifiedRegisteredUser = createSelector(
	[selectUser],
	(user) => user.unverifiedRegisteredUser
);

export const selectIsProcessingRegister = createSelector(
	[selectUser],
	(user) => user.isProcessingRegister
);

export const selectRegisterError = createSelector(
	[selectUser],
	(user) => user.registerError
);

// ================== UPDATE UNVERIFIED USER EMAIL SELECTORS ==================
export const selectUpdatedUnverifiedUser = createSelector(
	[selectUser],
	(user) => user.updatedUnverifiedUser
);

export const selectIsUpdatingUnverifiedEmail = createSelector(
	[selectUser],
	(user) => user.isUpdatingUnverifiedEmail
);

export const selectUpdateUnverifiedEmailError = createSelector(
	[selectUser],
	(user) => user.updateUnverifiedEmailError
);

// ================== SEND FORGOT PASSWORD EMAIL SELECTORS ==================
export const selectForgotPasswordEmailResponseData = createSelector(
	[selectUser],
	(user) => user.sendForgotPasswordEmailResponse
);

export const selectIsSendingForgotPasswordEmail = createSelector(
	[selectUser],
	(user) => user.isSendingForgotPasswordEmail
);

export const selectForgotPasswordEmailError = createSelector(
	[selectUser],
	(user) => user.sendForgotPasswordEmailError
);

// ================== FORGOT PASSWORD CHANGE SELECTORS ==================
export const selectForgotPasswordResponseData = createSelector(
	[selectUser],
	(user) => user.forgotPasswordChangeResponse
);

export const selectIsProcessingForgotPasswordChange = createSelector(
	[selectUser],
	(user) => user.isProcessingForgotPasswordChange
);

export const selectForgotPasswordChangeError = createSelector(
	[selectUser],
	(user) => user.forgotPasswordChangeError
);

// ================== RESET VERIFIED USER PASSWORD SELECTORS ==================
export const selectChangePasswordResponseData = createSelector(
	[selectUser],
	(user) => user.changeVerifiedUserPasswordResponse
);

export const selectIsChangingResetPassword = createSelector(
	[selectUser],
	(user) => user.isChangingVerifiedUserPassword
);

export const selectChangePasswordError = createSelector(
	[selectUser],
	(user) => user.changeVerifiedUserPasswordError
);

// ================== VERIFIED USER SELECTORS ==================
export const selectVerifiedUser = createSelector(
	[selectUser],
	(user) => user.verifiedUser
);

export const selectIsProcessingVerifyUser = createSelector(
	[selectUser],
	(user) => user.isProcessingVerifyUser
);

export const selectVerifyUserError = createSelector(
	[selectUser],
	(user) => user.verifyUserError
);

// ================== REFRESH TOKEN SELECTORS ==================
export const selectIsProcessingRefreshToken = createSelector(
	[selectUser],
	(user) => user.isProcessingRefreshToken
);

export const selectRefreshTokenError = createSelector(
	[selectUser],
	(user) => user.refreshTokenError
);

// ================== LOGOUT SELECTORS ==================
export const selectLogoutResponseData = createSelector(
	[selectUser],
	(user) => user.logoutResponseData
);

export const selectIsProcessingLogout = createSelector(
	[selectUser],
	(user) => user.isProcessingLogout
);

export const selectLogoutError = createSelector(
	[selectUser],
	(user) => user.logoutError
);
