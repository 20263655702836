import styled from 'styled-components';

export const LoaderContainer = styled.div`
	width: 100%;
	height: 100%;

	.lds-ellipsis {
		display: flex;
		justify-content: center;
		position: absolute;
		width: 160px;
		height: 160px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.lds-ellipsis div {
		position: absolute;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: #10b1dd;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	.lds-ellipsis div:nth-child(1) {
		left: 16px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 16px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 64px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 112px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(48px, 0);
		}
	}
`;
