import { CheckBox } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Style from './reset-password-success.styles';

const ResetPasswordSuccess = () => {
	const navigate = useNavigate();

	return (
		<Style.SuccessContainer>
			<CheckBox className='checkMark' />
			<Style.HeadingContainer>
				<Style.Heading>Success!</Style.Heading>
				<Style.SubHeading>
					Your new password has been successfully set up. <br />
					Click below to log in.
				</Style.SubHeading>
			</Style.HeadingContainer>
			<Style.LoginBtn onClick={() => navigate('/main/auth')}>
				Continue To Login
			</Style.LoginBtn>
		</Style.SuccessContainer>
	);
};

export default ResetPasswordSuccess;
