import React, { Dispatch, SetStateAction } from 'react';
import RegisterPasswordSetup from '../register-password-setup/register-password-setup.component';
import RegisterStart from '../register-start/register-start.component';
import RegisterVerification from '../register-verification/register-verification.component';
import * as Style from './register-process.styles';

interface RegisterProcessProps {
	// ============= PROPS =============
	// Monitor the active step of the register process (1, 2, 3)
	activeStep: number;
	// Set the active step of the register process (1, 2, 3)
	setActiveStep: Dispatch<SetStateAction<number>>;
	// Handle Toggle Event for swiching b/w login/register
	handleToggle: () => void;
}

const RegisterProcess = (props: RegisterProcessProps) => {
	return (
		<Style.RegisterContainer
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
		>
			{props.activeStep === 1 && (
				<RegisterStart
					setActiveStep={props.setActiveStep}
					handleToggle={props.handleToggle}
				/>
			)}
			{props.activeStep === 2 && (
				<RegisterVerification setActiveStep={props.setActiveStep} />
			)}
			{props.activeStep === 3 && (
				<RegisterPasswordSetup setActiveStep={props.setActiveStep} />
			)}
		</Style.RegisterContainer>
	);
};

export default RegisterProcess;
