import React from 'react';
import ResetPasswordForm from '../../components/forgot-password-process/reset-password-form/reset-password-form.component';
import * as Style from './reset-password.styles';

const ResetPassword = () => {
	return (
		<Style.AuthPage>
			<Style.AuthContainer>
				<Style.SecondaryContainer
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.6 }}
				>
					<Style.SliderContainer>
						<Style.Img
							src={
								process.env.PUBLIC_URL +
								'/assets/nutrimeals.webp'
							}
							alt='nutrimeals-logo'
						/>
					</Style.SliderContainer>
					<ResetPasswordForm />
				</Style.SecondaryContainer>
			</Style.AuthContainer>
		</Style.AuthPage>
	);
};

export default ResetPassword;
