import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgImage from '../../assets/background.webp';

export const AuthPage = styled.div`
	height: 100%;
	width: 100%;
`;

export const AuthContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	height: 100%;
	overflow: hidden;
	background-image: url(${bgImage});
	background-repeat: no-repeat;
	background-position-y: 30%;
	background-position-x: 100%;
	background-position: fixed;
	background-size: 140%;

	@media screen and (max-width: 1000px) {
		background-size: cover;
	}
`;
export const SecondaryContainer = styled(motion.div)`
	display: flex;
	flex-direction: column;
	margin: 27px 0;
	display: flex;
	background: #fff;
	border-top-left-radius: 25px;
	border-bottom-right-radius: 25px;
	width: 50%;
	min-width: 350px;
	max-width: 1000px;
`;

export const Img = styled.img`
	width: 55px;
	height: auto;
`;
export const SliderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
`;
