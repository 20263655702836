import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const SuccessContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	margin-top: 3rem;

	@media (max-height: 810px) {
		gap: 1rem;
	}

	@media (max-height: 730px) {
		gap: 0.5rem;
	}

	svg.checkMark {
		color: #00404b;
		font-size: 100px;

		@media (max-width: 975px) {
			font-size: 75px;
		}

		@media (max-height: 730px) {
			font-size: 65px;
		}
	}
`;

export const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

export const Heading = styled.h1`
	font-weight: 700;
	font-size: 2.2rem;
	color: #00404b;
	white-space: nowrap;
	margin: 0;

	@media (max-width: 975px) {
		font-size: 1.8rem;
	}
	@media (max-height: 730px) {
		font-size: 1.5rem;
	}
`;

export const SubHeading = styled.p`
	width: 100%;
	font-weight: 500;
	font-size: 1rem;
	color: #000;
	text-align: center;

	@media (max-width: 875px) {
		font-size: 0.9rem;
	}
`;

export const LoginBtn = muiStyled(Button)({
	fontFamily: 'inherit',
	textTransform: 'capitalize',
	fontSize: '1rem',
	fontWeight: 500,
	color: '#fff',
	backgroundColor: '#00404b',
	width: 'fit-content',
	padding: '0 3rem',
	height: '40px',
	borderRadius: '10px',
	whiteSpace: 'nowrap',
	minWidth: '252px',
	maxWidth: '252px',

	'&:hover': {
		backgroundColor: 'rgba(0, 64, 75, 0.74)',
	},
});
