import React, { useState } from 'react';
import Login from '../../components/login/login.component';
import * as Style from './authentication.styles';
import { AnimatePresence } from 'framer-motion';
import TimeLine from '../../components/register/timeline/timeline.component';
import RegisterProcess from '../../components/register/register-process/register-process.component';
import LogoOnlyNurishd from '../../assets/logo-only-nurishd';

const AuthenticationPage = () => {
	const [isLoginSelected, setIsLoginSelected] = useState<boolean>(true);

	const variants = {
		center: { x: '50%' },
		right: {
			x: 'calc(100% - 20%)',
		},
	};

	// Handle Toggle Event for swiching b/w login/register
	const handleToggle = () => {
		setIsLoginSelected(!isLoginSelected);
	};

	// ======= ACTIVE STEP FOR REGISTER PROCESS =================
	const [activeStep, setActiveStep] = useState(1);

	// State and handler function for displaying the forgot password form
	const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
	const handleShowForgotPasswordForm = () => {
		setShowForgotPasswordForm(!showForgotPasswordForm);
	};

	return (
		<Style.AuthPage>
			<Style.AuthContainer>
				<Style.TimeLineContainer
					animate={isLoginSelected ? { x: -350 } : { x: '11vw' }}
					transition={{ duration: 0.6, ease: 'easeInOut' }}
					initial={isLoginSelected ? { x: -350 } : { x: '11vw' }}
				>
					<TimeLine activeStep={activeStep} />
				</Style.TimeLineContainer>
				<AnimatePresence>
					<Style.SecondaryContainer
						initial={isLoginSelected ? 'center' : 'right'}
						animate={isLoginSelected ? 'center' : 'right'}
						variants={variants}
						transition={{ duration: 0.6 }}
					>
						<Style.SliderContainer>
							<LogoOnlyNurishd />
							{!showForgotPasswordForm && (
								<Style.Slider>
									<Style.Label className='toggle-switch'>
										<Style.FieldInput
											type='checkbox'
											onChange={handleToggle}
											checked={isLoginSelected}
										/>
										<Style.Span className='switch' />
										<Style.Span
											className='label-left'
											style={{
												color: isLoginSelected
													? '#00404B'
													: '#EAF2F5',
											}}
										>
											Register
										</Style.Span>
										<Style.Span
											className='label-right'
											style={{
												color: isLoginSelected
													? '#EAF2F5'
													: '#00404B',
											}}
										>
											Login
										</Style.Span>
									</Style.Label>
								</Style.Slider>
							)}
						</Style.SliderContainer>
						<Style.AuthOptionContainer>
							{isLoginSelected ? (
								<Login
									showForgotPasswordForm={
										showForgotPasswordForm
									}
									handleToggle={handleToggle}
									handleShowForgotPasswordForm={
										handleShowForgotPasswordForm
									}
								/>
							) : (
								<RegisterProcess
									handleToggle={handleToggle}
									activeStep={activeStep}
									setActiveStep={setActiveStep}
								/>
							)}
						</Style.AuthOptionContainer>
					</Style.SecondaryContainer>
				</AnimatePresence>
			</Style.AuthContainer>
		</Style.AuthPage>
	);
};

export default AuthenticationPage;
