import styled from 'styled-components';
import { styled as muistyled } from '@mui/material/styles';
import { Button, Modal } from '@mui/material';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;

	@media (max-height: 810px) {
		gap: 1rem;
	}

	@media (max-height: 730px) {
		gap: 0.5rem;
	}

	svg.checkMark {
		color: #00404b;
		font-size: 100px;

		@media (max-width: 975px) {
			font-size: 75px;
		}

		@media (max-height: 730px) {
			font-size: 65px;
		}
	}
`;

export const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;

	@media (max-width: 975px) {
		gap: 10px;
	}
`;

export const Heading = styled.h1`
	font-weight: 700;
	font-size: 2.2rem;
	color: #00404b;
	white-space: nowrap;
	margin: 0;

	@media (max-width: 975px) {
		font-size: 1.8rem;
	}
	@media (max-height: 730px) {
		font-size: 1.5rem;
	}
`;

export const PasswordForm = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;

	@media (max-height: 810px) {
		gap: 1rem;
	}

	@media (max-height: 730px) {
		gap: 0.5rem;
	}
`;

export const ModalHeading = styled(Heading)`
	font-size: 1.5rem;
`;

export const SkipModal = muistyled(Modal)({});

export const SubHeading = styled.h2`
	font-weight: 600;
	font-size: 1rem;
	color: #000;
	white-space: nowrap;
	margin: 0;
`;

export const Error = styled.p`
	margin: 0;
	padding: 0;
	color: #ff0000;
	font-size: 0.8rem;
	line-height: 1.1;
	max-width: 100%;
	position: absolute;
	bottom: -12px;
`;

export const InputFieldsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 500px;
	min-width: 300px;
	gap: 1rem;
	padding: 0 1rem;
	margin-bottom: 20px;

	@media (max-width: 780px) {
		max-width: 300px;
		min-width: 200px;
	}

	&:focus-within {
		border-color: rgba(0, 64, 75, 0.74);
	}

	@media (max-height: 810px) {
		gap: 0.5rem;
	}
`;

export const Links = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const Link = styled.a`
	font-size: 15px;
	font-weight: 600;
	color: #00404b;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.5s ease;
	white-space: nowrap;
`;

export const SkipContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
`;

export const Desc = styled.p`
	font-size: 15px;
	font-weight: 500;
	color: #000;
	white-space: nowrap;

	@media (max-width: 975px) {
		width: 350px;
		white-space: normal;
	}
`;

export const Description = styled.p`
	font-size: 0.9rem;
	color: #000;
	font-weight: 500;
	margin: 1rem 0 0.4rem;
`;

export const ModalDesc = styled.div`
	font-size: 16px;
	white-space: normal;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
`;

export const TempPassword = styled.span`
	font-size: 16px;
	font-weight: 700;
	color: #ff9700;
	white-space: nowrap;
`;

export const BtnContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
`;

export const ModalContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	gap: 1rem;
	transform: translate(-50%, -50%);
	width: 400px;
	border: 5px solid #eaf2f5;
	border-radius: 10px;
	flex-direction: column;
	text-align: center;
	z-index: 1000;
	padding: 2rem;
	background-color: #fff;
`;

export const CopyPassword = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 1rem;
`;

export const Btn = muistyled(Button)({
	fontFamily: 'inherit',
	textTransform: 'capitalize',
	fontSize: '1rem',
	fontWeight: 500,
	color: '#fff',
	backgroundColor: '#00404b',
	width: 'fit-content',
	padding: '0 3rem',
	height: '40px',
	borderRadius: '10px',
	whiteSpace: 'nowrap',
	minWidth: '165px',

	'&:hover': {
		backgroundColor: 'rgba(0, 64, 75, 0.74)',
	},
});

export const CopyBtn = muistyled(Button)({
	color: '#00404b',
	backgroundColor: 'none',
	maxWidth: '40px',
	maxHeight: '40px',
	minWidth: '30px',
	minHeight: '30px',
	padding: '0',
	borderRadius: '10px',

	'&:hover': {
		backgroundColor: '#EAF2F5',
	},
});

export const CancelBtn = muistyled(Btn)({
	backgroundColor: '#636668',

	'&:hover': {
		backgroundColor: 'rgba(115, 119, 121, 0.74)',
	},
});

export const InputField = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	min-width: 210px;
	position: relative;
`;

export const PasswordRequirementsList = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 0.9rem;
	color: #000;
	font-weight: 500;
	width: 100%;

	list-style: none;
	margin: 0;
	padding: 0;
`;

export const LineContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	width: 100%;
`;

export const Line = styled.div`
	display: block;
	width: 25%;
	height: 3px;
	background: #b7c5cf;
	border-radius: 3px;
	background-position: right bottom;

	&:active {
		background-position: left bottom;
	}

	width: 100%;
	background: ${({ color }) =>
		`linear-gradient(to right, ${color} 50%, #b7c5cf 50%)`};
	background-size: 200% 100%;
	transition: all 0.5s ease-in-out;
`;

export const ListItem = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	max-height: 22px;

	&::before {
		color: #b7c5cf;
		position: relative;
		bottom: 1px;
		content: '\u25CF';
		font-size: 1.5rem;
		font-weight: 600;
		margin-right: 0.5rem;
	}
	&.met {
		color: #24a33d;
	}

	&.met::before {
		color: #24a33d;
	}
`;

export const RequirementsContainer = styled.div`
	width: 250px;
	position: absolute;
	z-index: 1000;
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	padding: 18px;
	border-radius: 10px;
	border: 2px solid #d6e2e8;
	top: 5.2rem;

	/* Top left corner arrow */
	&::after {
		content: '';
		position: absolute;
		bottom: 96%;
		left: 10%;
		height: 16px;
		width: 16px;
		border-top: 2px solid #d6e2e8;
		border-left: 2px solid #d6e2e8;
		border-bottom: none;
		border-right: none;
		border-top-left-radius: 4px;
		background-color: #fff;
		transform: rotateY(0deg) rotate(45deg);
	}
`;

export const PasswordLength = styled.p`
	font-size: 1.05rem;
	white-space: nowrap;
	color: #000;
	font-weight: 700;
	margin-top: 0;

	&.met {
		color: #24a33d;
	}
`;

export const PasswordRequirementsPopup = styled.div``;

export const InputFieldLabel = styled.label`
	font-size: 1rem;
	font-weight: 600;
	color: #000;

	@media (max-height: 730px) {
		font-size: 0.9rem;
	}

	@media (max-width: 975px) {
		font-size: 0.9rem;
	}
`;

export const NewPasswordContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
`;

export const InputFieldInput = styled.input`
	box-sizing: border-box;
	width: 100%;
	height: 3rem;
	border: 1px solid #b7c5cf;
	padding: 0.5rem;
	margin: 4px 0;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 10px;
	background-color: #fff;
	position: relative;

	::placeholder {
		color: #b7c5cf;
	}

	&:hover {
		border: 1px solid rgba(16, 177, 221, 0.5);
	}

	&:focus {
		outline: none;
		border: 1px solid #10b1dd;
	}

	/* Hide the show/hide icon in Chrome, Edge, and Safari */
	input[type='password']::-ms-reveal,
	input[type='password']::-ms-clear,
	input[type='password']::-webkit-contacts-auto-fill-button {
		color: none;
		font-size: 0px;
		display: none;
	}
	input::-ms-reveal,
	input::-ms-clear,
	::-ms-reveal,
	::-ms-clear {
		display: none;
	}
	input[type='password']::-webkit-contacts-auto-fill-button {
		display: none !important;
	}
`;
export const InputFieldButton = muistyled(Button)({
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-15%)',
	right: '20px',
	background: 'none',
	border: 'none',
	fontSize: '0.9rem',
	color: '#333',
	cursor: 'pointer',
	maxWidth: '30px',
	minWidth: '30px',
	maxHeight: '30px',
	minHeight: '30px',
	padding: '0',

	'@media (max-width: 975px)': {
		transform: 'translateY(-20%)',
	},
});
