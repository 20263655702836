import { ForgotPasswordOutputSchema } from './../../schemas/user/authentication.schemas';
import {
	PersonalProfileOutputSchema,
	GenericResponseSchema,
} from './../../schemas/user/manage-self.schemas';
import { AxiosError } from 'axios';
import { z } from 'zod';
import {
	LoginResponseSchema,
	LogoutResponseSchema,
} from '../../schemas/user/authentication.schemas';
import { UserOutputSchema } from '../../schemas/user/manage-users.schemas';
import {
	UnverifiedUserOutputSchema,
	UserOnboardingVerificationResponseSchema,
} from '../../schemas/user/new-user.schemas';
import { UserActionInterface } from './user.actions';
import UserActionTypes from './user.types';

export interface UserReducerState {
	// ============= AUTHENTICATION VARIABLES ============= //
	// LOGIN VARIABLES
	currentUser: z.infer<typeof UserOutputSchema> | null;
	resourceTokens: z.infer<typeof LoginResponseSchema> | null;
	isProcessingLogin: boolean;
	loginError: AxiosError | string | null;

	// LOGOUT VARIABLES
	logoutResponseData: z.infer<typeof LogoutResponseSchema> | null;
	isProcessingLogout: boolean;
	logoutError: AxiosError | string | null;

	// REFRESH TOKEN VARIABLES
	isProcessingRefreshToken: boolean;
	refreshTokenError: AxiosError | string | null;

	// ============= NEW USER REGISTRATION VARIABLES ============= //

	// REGISTER VARIABLES
	unverifiedRegisteredUser: z.infer<typeof UnverifiedUserOutputSchema> | null;
	isProcessingRegister: boolean;
	registerError: AxiosError | string | null;

	// UPDATE UNVERIFIED EMAIL VARIABLES
	isUpdatingUnverifiedEmail: boolean;
	updateUnverifiedEmailError: AxiosError | string | null;
	updatedUnverifiedUser: z.infer<typeof UnverifiedUserOutputSchema> | null;

	// CHANGE VERIFIED USER PASSWORD VARIABLES
	isChangingVerifiedUserPassword: boolean;
	changeVerifiedUserPasswordError: AxiosError | string | null;
	changeVerifiedUserPasswordResponse: z.infer<
		typeof PersonalProfileOutputSchema
	> | null;

	// VERIFY USER VARIABLES
	verifiedUser: z.infer<
		typeof UserOnboardingVerificationResponseSchema
	> | null;
	isProcessingVerifyUser: boolean;
	verifyUserError: AxiosError | string | null;

	// SEND FORGOT PASSWORD EMAIL VARIABLES
	isSendingForgotPasswordEmail: boolean;
	sendForgotPasswordEmailError: AxiosError | string | null;
	sendForgotPasswordEmailResponse: z.infer<
		typeof ForgotPasswordOutputSchema
	> | null;

	// FORGOT PASSWORD VARIABLES
	isProcessingForgotPasswordChange: boolean;
	forgotPasswordChangeError: AxiosError | string | null;
	forgotPasswordChangeResponse: z.infer<typeof GenericResponseSchema> | null;
}

const INITIAL_STATE: UserReducerState = {
	// LOGIN VARIABLES
	currentUser: null,
	resourceTokens: null,
	isProcessingLogin: false,
	loginError: null,

	// LOGOUT VARIABLES
	logoutResponseData: null,
	isProcessingLogout: false,
	logoutError: null,

	// REFRESH TOKEN VARIABLES
	isProcessingRefreshToken: false,
	refreshTokenError: null,

	// REGISTER VARIABLES
	unverifiedRegisteredUser: null,
	isProcessingRegister: false,
	registerError: null,

	// UPDATE UNVERIFIED EMAIL VARIABLES
	isUpdatingUnverifiedEmail: false,
	updateUnverifiedEmailError: null,
	updatedUnverifiedUser: null,

	// CHANGE VERIFIED USER PASSWORD VARIABLES
	isChangingVerifiedUserPassword: false,
	changeVerifiedUserPasswordError: null,
	changeVerifiedUserPasswordResponse: null,

	// VERIFY USER VARIABLES
	verifiedUser: null,
	isProcessingVerifyUser: false,
	verifyUserError: null,

	// SEND FORGOT PASSWORD EMAIL VARIABLES
	isSendingForgotPasswordEmail: false,
	sendForgotPasswordEmailError: null,
	sendForgotPasswordEmailResponse: null,

	// FORGOT PASSWORD VARIABLES
	isProcessingForgotPasswordChange: false,
	forgotPasswordChangeError: null,
	forgotPasswordChangeResponse: null,
};

function userReducer(
	state: UserReducerState = INITIAL_STATE,
	action: UserActionInterface
): UserReducerState {
	switch (action.type) {
		// ===================== LOGIN PROCESS ===================
		case UserActionTypes.LOGIN_START:
			return {
				...state,
				currentUser: null,
				resourceTokens: null,
				isProcessingLogin: true,
				loginError: null,
			};
		case UserActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				currentUser: (
					action.payload as {
						resourceTokens: z.infer<typeof LoginResponseSchema>;
						currentUser: z.infer<typeof UserOutputSchema>;
					}
				).currentUser,
				resourceTokens: (
					action.payload as {
						resourceTokens: z.infer<typeof LoginResponseSchema>;
						currentUser: z.infer<typeof UserOutputSchema>;
					}
				).resourceTokens,
				isProcessingLogin: false,
				loginError: null,
			};
		case UserActionTypes.LOGIN_FAILURE:
			return {
				...state,
				currentUser: null,
				resourceTokens: null,
				isProcessingLogin: false,
				loginError: action.payload as AxiosError | string,
			};

		// ============ USER REGISTER PROCESS ================
		case UserActionTypes.REGISTER_START:
			return {
				...state,
				unverifiedRegisteredUser: null,
				isProcessingRegister: true,
				registerError: null,
			};
		case UserActionTypes.REGISTER_SUCCESS:
			return {
				...state,
				unverifiedRegisteredUser: action.payload as z.infer<
					typeof UnverifiedUserOutputSchema
				>,
				isProcessingRegister: false,
				registerError: null,
			};
		case UserActionTypes.REGISTER_FAILURE:
			return {
				...state,
				unverifiedRegisteredUser: null,
				isProcessingRegister: false,
				registerError: action.payload as AxiosError | string,
			};

		// ============ USER UPDATE UNVERIFIED EMAIL PROCESS ================
		case UserActionTypes.UPDATE_UNVERIFIED_EMAIL_START:
			return {
				...state,
				updatedUnverifiedUser: null,
				isUpdatingUnverifiedEmail: true,
				updateUnverifiedEmailError: null,
			};
		case UserActionTypes.UPDATE_UNVERIFIED_EMAIL_SUCCESS:
			return {
				...state,
				updatedUnverifiedUser: action.payload as z.infer<
					typeof UnverifiedUserOutputSchema
				>,
				isUpdatingUnverifiedEmail: false,
				updateUnverifiedEmailError: null,
			};
		case UserActionTypes.UPDATE_UNVERIFIED_EMAIL_FAILURE:
			return {
				...state,
				updatedUnverifiedUser: null,
				isUpdatingUnverifiedEmail: false,
				updateUnverifiedEmailError: action.payload as
					| AxiosError
					| string,
			};

		// ============ CHANGE VERIFIED USER PASSWORD PROCESS ================
		case UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_START:
			return {
				...state,
				changeVerifiedUserPasswordResponse: null,
				isChangingVerifiedUserPassword: true,
				changeVerifiedUserPasswordError: null,
			};
		case UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_SUCCESS:
			return {
				...state,
				changeVerifiedUserPasswordResponse: action.payload as z.infer<
					typeof PersonalProfileOutputSchema
				>,
				isChangingVerifiedUserPassword: false,
				changeVerifiedUserPasswordError: null,
			};
		case UserActionTypes.CHANGE_VERIFIED_USER_PASSWORD_FAILURE:
			return {
				...state,
				changeVerifiedUserPasswordResponse: null,
				isChangingVerifiedUserPassword: false,
				changeVerifiedUserPasswordError: action.payload as
					| AxiosError
					| string,
			};

		// ============ SEND FORGOT PASSWORD EMAIL ================
		case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_START:
			return {
				...state,
				sendForgotPasswordEmailResponse: null,
				isSendingForgotPasswordEmail: true,
				sendForgotPasswordEmailError: null,
			};
		case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
			return {
				...state,
				sendForgotPasswordEmailResponse: action.payload as z.infer<
					typeof ForgotPasswordOutputSchema
				>,
				isSendingForgotPasswordEmail: false,
				sendForgotPasswordEmailError: null,
			};

		case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
			return {
				...state,
				sendForgotPasswordEmailResponse: null,
				isSendingForgotPasswordEmail: false,
				sendForgotPasswordEmailError: action.payload as
					| AxiosError
					| string,
			};

		// ============ FORGOT PASSWORD CHANGE PROCESS ================
		case UserActionTypes.FORGOT_PASSWORD_CHANGE_START:
			return {
				...state,
				forgotPasswordChangeResponse: null,
				isProcessingForgotPasswordChange: true,
				forgotPasswordChangeError: null,
			};
		case UserActionTypes.FORGOT_PASSWORD_CHANGE_SUCCESS:
			return {
				...state,
				forgotPasswordChangeResponse: action.payload as z.infer<
					typeof GenericResponseSchema
				>,
				isProcessingForgotPasswordChange: false,
				forgotPasswordChangeError: null,
			};
		case UserActionTypes.FORGOT_PASSWORD_CHANGE_FAILURE:
			return {
				...state,
				forgotPasswordChangeResponse: null,
				isProcessingForgotPasswordChange: false,
				forgotPasswordChangeError: action.payload as
					| AxiosError
					| string,
			};

		// ============ USER LOGOUT PROCESS ================
		case UserActionTypes.USER_LOGOUT_START:
			return {
				...state,
				logoutResponseData: null,
				isProcessingLogout: true,
				logoutError: null,
			};
		case UserActionTypes.USER_LOGOUT_SUCCESS:
			return {
				...INITIAL_STATE,
				logoutResponseData: action.payload as z.infer<
					typeof LogoutResponseSchema
				>,
				isProcessingLogout: false,
				logoutError: null,
			};
		case UserActionTypes.USER_LOGOUT_FAILURE:
			return {
				...INITIAL_STATE,
				logoutResponseData: null,
				isProcessingLogin: false,
				registerError: action.payload as AxiosError | string,
			};

		// ============ USER REFRESH TOKEN PROCESS ================
		case UserActionTypes.USER_REFRESH_TOKEN_START:
			return {
				...state,
				isProcessingRefreshToken: true,
				refreshTokenError: null,
			};
		case UserActionTypes.USER_REFRESH_TOKEN_SUCCESS:
			return {
				...state,
				resourceTokens: action.payload as z.infer<
					typeof LoginResponseSchema
				>,
				isProcessingRefreshToken: false,
				refreshTokenError: null,
			};
		case UserActionTypes.USER_REFRESH_TOKEN_FAILURE:
			return {
				...state,
				isProcessingRefreshToken: false,
				refreshTokenError: action.payload as AxiosError | string,
			};

		// ============ USER VERIFY PROCESS ================
		case UserActionTypes.USER_VERIFY_START:
			return {
				...state,
				verifiedUser: null,
				isProcessingVerifyUser: true,
				verifyUserError: null,
			};
		case UserActionTypes.USER_VERIFY_SUCCESS:
			return {
				...state,
				verifiedUser: action.payload as z.infer<
					typeof UserOnboardingVerificationResponseSchema
				>,
				isProcessingVerifyUser: false,
				verifyUserError: null,
			};
		case UserActionTypes.USER_VERIFY_FAILURE:
			return {
				...state,
				verifiedUser: null,
				isProcessingVerifyUser: false,
				verifyUserError: action.payload as AxiosError | string,
			};
		// ==================================================
		default:
			return state;
	}
}

export default userReducer;
