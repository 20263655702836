// =================== ENVIRONMENT VARIABLES ===========================
// ------------------- MICROSERVICE URLS -------------------------------
let AUTH_MICROSERVICE_URL = '';
// ------------------- MICRO FRONTEND URLS -----------------------------
let MAIN_MICROFRONTEND_URL = '';

// =================== VALUES FOR ENVIRONMENT VARIABLES ================
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
	// Production
	AUTH_MICROSERVICE_URL = 'https://apis.internal.nutrimeals.ca/auth';
	MAIN_MICROFRONTEND_URL = 'https://web.internal.nutrimeals.ca/main';
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
	// Development
	AUTH_MICROSERVICE_URL = 'https://apis.internal-dev.nutrimeals.ca/auth';
	MAIN_MICROFRONTEND_URL = 'https://web.internal-dev.nutrimeals.ca/main';
} else {
	// Local
	AUTH_MICROSERVICE_URL = 'https://apis.internal-dev.nutrimeals.ca/auth';
	MAIN_MICROFRONTEND_URL = 'https://web.internal-dev.nutrimeals.ca/main';
}

export const APP_ENV = {
	AUTH_MICROSERVICE_URL,
	MAIN_MICROFRONTEND_URL,
};
