import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
	ArrowBackOutlined,
	Lock,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createStructuredSelector } from 'reselect';
import { z } from 'zod';
import {
	forgotPasswordChangeStart,
	UserActionInterface,
} from '../../../redux/user/user.actions';
import {
	selectForgotPasswordChangeError,
	selectForgotPasswordResponseData,
	selectIsProcessingForgotPasswordChange,
} from '../../../redux/user/user.selectors';
import {
	GenericResponseSchema,
	PersonalProfileResetPasswordInputSchema,
} from '../../../schemas/user/manage-self.schemas';
import * as Style from './reset-password-form.styles';
import { useNavigate, useParams } from 'react-router-dom';
import ResetPasswordSuccess from '../reset-password-success/reset-password-success.component';

interface ResetPasswordProps {
	// REDUX PROPS
	isProcessingForgotPasswordChange: boolean;
	forgotPasswordChangeError: AxiosError | string | null;
	forgotPasswordChangeResponse: z.infer<typeof GenericResponseSchema> | null;

	// REDUX ACTIONS
	forgotPasswordChangeStart: (
		verificationCode: string,
		resetPasswordData: z.infer<
			typeof PersonalProfileResetPasswordInputSchema
		>
	) => UserActionInterface;
}

type resetPasswordSchema = z.infer<
	typeof PersonalProfileResetPasswordInputSchema
>;

const ResetPasswordForm = (props: ResetPasswordProps) => {
	const navigate = useNavigate();
	const { verificationCode } = useParams();
	// =================== SUBMIT NEW PASSWORD HANDLER ===================
	const methods = useForm<resetPasswordSchema>({
		resolver: zodResolver(PersonalProfileResetPasswordInputSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	const handleResetPassword: SubmitHandler<resetPasswordSchema> = (data) => {
		if (data !== undefined || data !== null) {
			props.forgotPasswordChangeStart(
				verificationCode as string,
				data as z.infer<typeof PersonalProfileResetPasswordInputSchema>
			);
		}
	};

	// =================== HANDLE ANY ERRORS ===================
	// Error handling
	const [errorText, setErrorText] = React.useState<string>('');
	useEffect(() => {
		if (props.forgotPasswordChangeError) {
			if (typeof props.forgotPasswordChangeError === 'string') {
				setErrorText(props.forgotPasswordChangeError);
			} else if (axios.isAxiosError(props.forgotPasswordChangeError)) {
				setErrorText(
					props.forgotPasswordChangeError.response?.data.detail
						.map(
							(error: { msg: string; type: string }) =>
								`${error.msg} (${error.type})`
						)
						.join(', ')
				);
			}
		}
		console.log(errorText);
	}, [props.forgotPasswordChangeError, errorText]);

	// =================== PASSWORD STRENGTH CHECKER ===================
	useEffect(() => {
		const password = methods.watch(`new_password`);
		setPasswordStrength({
			hasEightCharacters: password?.length >= 8,
			hasUpperAndLowercase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
			hasNumber: /\d/.test(password),
			hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
		});
	}, [methods.watch(`new_password`)]);

	// =================== PASSWORD HANDLING ===================
	const [showPassword, setShowPassword] = useState(false);

	// Password requirements popup
	const [showPopup, setShowPopup] = useState(false);
	const [passwordStrength, setPasswordStrength] = useState({
		hasEightCharacters: false,
		hasUpperAndLowercase: false,
		hasNumber: false,
		hasSpecialChar: false,
	});

	// Check how many of the password strength conditions are met
	const trueValues = Object.values(passwordStrength).filter(
		(value) => value === true
	);
	const isAtLeastOneTrue = trueValues?.length >= 1;
	const isAtLeastTwoTrue = trueValues?.length >= 2;
	const isAtLeastThreeTrue = trueValues?.length >= 3;
	const isAllTrue = trueValues?.length >= 4;

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Style.MainContainer>
			{props.forgotPasswordChangeResponse === null && (
				<Style.Container
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.8 }}
				>
					<Lock className='lock' />
					<Style.HeadingContainer>
						<Style.Heading>Set New Password</Style.Heading>
						<Style.SubHeading>
							Create a new password for your account.
						</Style.SubHeading>
					</Style.HeadingContainer>
					<Style.PasswordForm
						onSubmit={methods.handleSubmit(handleResetPassword)}
					>
						<Style.InputFieldsContainer>
							<Style.NewPasswordContainer>
								<Style.InputField>
									<Style.InputFieldLabel>
										New Password
									</Style.InputFieldLabel>
									<Style.InputFieldInput
										type={
											showPassword ? 'text' : 'password'
										}
										placeholder='New Password'
										{...methods.register(`new_password`)}
										onFocus={() => setShowPopup(true)}
										onBlur={() => {
											setShowPopup(false);
											methods.trigger(`new_password`);
										}}
										style={
											methods.formState.errors
												.new_password && {
												border: '2px solid red',
												padding: '7px',
											}
										}
									/>
									<Style.InputFieldButton
										onClick={toggleShowPassword}
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</Style.InputFieldButton>
									<ErrorMessage
										errors={methods.formState.errors}
										name='new_password'
										render={({ message }) => (
											<Style.Error>{message}</Style.Error>
										)}
									/>
								</Style.InputField>
								<Style.RequirementsContainer
									style={{
										display:
											showPopup === true
												? 'block'
												: 'none',
									}}
								>
									<Style.PasswordRequirementsPopup>
										<Style.PasswordLength>
											Must have at least 8 characters.
										</Style.PasswordLength>
										<Style.LineContainer>
											<Style.Line
												color={
													isAtLeastOneTrue
														? '#fc7f12'
														: ''
												}
											/>
											<Style.Line
												color={
													isAtLeastTwoTrue
														? '#fdbe00'
														: ''
												}
											/>
											<Style.Line
												color={
													isAtLeastThreeTrue
														? '#aee63e'
														: ''
												}
											/>
											<Style.Line
												color={
													isAllTrue ? '#24a33d' : ''
												}
											/>
										</Style.LineContainer>
										<Style.Description>
											It&apos;s better to have:{' '}
										</Style.Description>
										<Style.PasswordRequirementsList>
											<Style.ListItem
												className={
													passwordStrength.hasUpperAndLowercase
														? 'met'
														: ''
												}
											>
												Upper & lowercase letters
											</Style.ListItem>
											<Style.ListItem
												className={
													passwordStrength.hasSpecialChar
														? 'met'
														: ''
												}
											>
												A symbol (#$&)
											</Style.ListItem>
											<Style.ListItem
												className={
													passwordStrength.hasNumber
														? 'met'
														: ''
												}
											>
												Number(s)
											</Style.ListItem>
										</Style.PasswordRequirementsList>
									</Style.PasswordRequirementsPopup>
								</Style.RequirementsContainer>
							</Style.NewPasswordContainer>
							<Style.InputField>
								<Style.InputFieldLabel>
									Confirm New Password
								</Style.InputFieldLabel>
								<Style.InputFieldInput
									type={showPassword ? 'text' : 'password'}
									placeholder='Confirm New Password'
									{...methods.register(
										`confirm_new_password`
									)}
									style={
										methods.formState.errors
											.confirm_new_password && {
											border: '2px solid red',
											padding: '7px',
										}
									}
								/>
								<ErrorMessage
									errors={methods.formState.errors}
									name='confirm_new_password'
									render={({ message }) => (
										<Style.Error>{message}</Style.Error>
									)}
								/>
							</Style.InputField>
						</Style.InputFieldsContainer>
						<Style.BtnContainer>
							<Style.Btn
								type='submit'
								onClick={methods.handleSubmit(
									handleResetPassword
								)}
							>
								{props.isProcessingForgotPasswordChange ? (
									<CircularProgress
										size={24}
										style={{ color: '#FFF' }}
									/>
								) : (
									'Set New Password'
								)}
							</Style.Btn>
						</Style.BtnContainer>
						<Style.Links>
							<Style.Link onClick={() => navigate('/auth')}>
								<ArrowBackOutlined /> Back to Login
							</Style.Link>
						</Style.Links>
						{errorText !== null && (
							<Style.ErrorText>{errorText}</Style.ErrorText>
						)}
					</Style.PasswordForm>
				</Style.Container>
			)}
			{props.forgotPasswordChangeResponse !== null && (
				<ResetPasswordSuccess />
			)}
		</Style.MainContainer>
	);
};

const mapStateToProps = createStructuredSelector({
	isProcessingForgotPasswordChange: selectIsProcessingForgotPasswordChange,
	forgotPasswordChangeError: selectForgotPasswordChangeError,
	forgotPasswordChangeResponse: selectForgotPasswordResponseData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	forgotPasswordChangeStart: (
		verificationCode: string,
		resetPasswordData: z.infer<
			typeof PersonalProfileResetPasswordInputSchema
		>
	) =>
		dispatch(
			forgotPasswordChangeStart(verificationCode, resetPasswordData)
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
