import z from 'zod';
import OperationStatusEnum from '../../enums/operation-status.enum';
import RoleBasedAccessControlEnum from '../../enums/roles.enum';

export const PersonalProfileOutputSchema = z.object({
	user_id: z.string().uuid(),
	username: z
		.string()
		.min(8)
		.max(50)
		.regex(/^[a-zA-Z][a-zA-Z0-9@_\-\$]{7,50}$/),
	email: z.object({
		email_id: z.string().email(),
		is_email_verified: z.boolean(),
	}),
	phone: z.object({
		phone_number: z.string().regex(/^$|^[0-9\+]+$/),
		is_phone_verified: z.boolean(),
	}),
	first_name: z
		.string()
		.max(100)
		.regex(/^$|^(?!\s)([a-zA-Z\s]*?)(?<!\s)$/),
	last_name: z
		.string()
		.max(100)
		.regex(/^$|^(?!\s)([a-zA-Z\s]*?)(?<!\s)$/),
	profile_picture: z.string(),
	roles: z.nativeEnum(RoleBasedAccessControlEnum),
	disabled: z.boolean({
		description: 'Kill switch to shut off all access of resources',
	}),
	marked_for_delete: z.boolean({
		description:
			'If this is set true, then the corresponding user data should be deleted within 30 days according to GDPR data policy',
	}),
	created_at: z.coerce.date(),
	updated_at: z.coerce.date(),
});

export const PersonalProfileUpdateSchema = z.object({
	username: z
		.string()
		.min(8)
		.max(50)
		.regex(/^[a-zA-Z][a-zA-Z0-9@_\-\$]{7,50}$/),
	email: z.object({
		email_id: z.string().email(),
	}),
	phone: z.object({
		phone_number: z.string().regex(/^$|^[0-9\+]+$/),
	}),
	first_name: z
		.string()
		.max(100)
		.regex(/^$|^(?!\s)([a-zA-Z\s]*?)(?<!\s)$/),
	last_name: z
		.string()
		.max(100)
		.regex(/^$|^(?!\s)([a-zA-Z\s]*?)(?<!\s)$/),
});

export const GenericResponseSchema = z.object({
	status: z.nativeEnum(OperationStatusEnum),
	detail: z.string(),
});

export const PersonalProfileChangePasswordSchema = z.object({
	old_password: z
		.string()
		.min(8)
		.max(128)
		.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}$/),
	new_password: z
		.string({ required_error: 'Password is required' })
		.min(8, { message: 'Password must be at least 8 characters long' })
		.max(128, { message: 'Password cannot be more than 50 characters' })
		.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}$/, {
			message: 'Invalid Password',
		}),
	confirm_new_password: z
		.string({ required_error: 'Password is required' })
		.min(8)
		.max(128)
		.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}$/),
});

export const PersonalProfileResetPasswordInputSchema = z.object({
	new_password: z
		.string()
		.min(8, { message: 'Password must be at least 8 characters long' })
		.max(128, { message: 'Password cannot be more than 50 characters' })
		.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}$/, {
			message: 'Invalid Password',
		}),
	confirm_new_password: z
		.string()
		.min(8)
		.max(128)
		.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}$/),
});

export const ForgotPasswordEmailSchema = z.object({
	email: z
		.string()
		.min(1, { message: 'Email is required' })
		.email({ message: 'Invalid Email' }),
});

export const ForgotPasswordSchema = z.object({
	is_password_reset_link_sent: z.boolean(),
	password_reset_link_issuing_authority: z.string(),
	password_reset_link_issued_at: z.coerce.date(),
});
