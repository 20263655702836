import styled from 'styled-components';
import { motion } from 'framer-motion';

export const RegisterContainer = styled(motion.div)`
	/* display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column; */
	width: 50%;
`;
