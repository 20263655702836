import z from 'zod';
import RoleBasedAccessControlEnum from '../../enums/roles.enum';
import { LoginResponseSchema } from './authentication.schemas';

export const UnverifiedUserCreateSchema = z.object({
	username: z
		.string()
		.min(1, { message: 'Username is required' })
		.min(8, { message: 'Username must be at least 8 characters' })
		.max(50, { message: 'Username must be less than 50 characters' })
		.regex(/^[a-zA-Z][a-zA-Z0-9@_\-\$]{7,50}$/),
	email: z
		.string()
		.min(1, { message: 'Email is required' })
		.email({ message: 'Invalid Email' }),
});

export const UnverifiedUserOutputSchema = UnverifiedUserCreateSchema.extend({
	user_id: z.string().uuid(),
	expires_on: z.coerce.date({
		description:
			'Date on which this data will be removed from the DB - UTC Timezone',
	}),
	created_on: z.coerce.date({
		description: 'Date on which this data was created - UTC Timezone',
	}),
});

// Allows update of username and email of an unverified user in Nutrimeals.
export const UnverifiedUserUpdateSchema = UnverifiedUserCreateSchema.extend({});

export const UnverifiedUserVerificationSchema =
	UnverifiedUserCreateSchema.extend({
		otp_code: z.string({
			description:
				'Verification Code which will be used to verify the user',
		}),
	});

export const UserOnboardingVerificationResponseSchema =
	UnverifiedUserCreateSchema.extend({
		user_id: z.string().uuid(),
		roles: z.nativeEnum(RoleBasedAccessControlEnum),
		random_password: z
			.string()
			.min(8)
			.max(128)
			.regex(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,128}/),
		tokens: LoginResponseSchema,
	});
