import React from 'react';
import * as Style from './App.styles';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthenticationPage from './pages/authentication/authentication.page';
import { LoginResponseSchema } from './schemas/user/authentication.schemas';
import { z } from 'zod';
import Loader from './pages/loader/loader.page';
import { selectResourceTokens } from './redux/user/user.selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ResetPassword from './pages/reset-password/reset-password.page';

interface AppProps {
	resourceTokens: z.infer<typeof LoginResponseSchema> | null;
}
function App(props: AppProps) {
	return (
		<Style.AppContainer>
			<Routes>
				<Route path='/auth' element={<AuthenticationPage />} />
				<Route
					path='/auth/reset-password/:verificationCode'
					element={<ResetPassword />}
				/>
				{props.resourceTokens !== null ? (
					<Route path='/auth/loading' element={<Loader />} />
				) : (
					<Route path='*' element={<Navigate to='/auth' replace />} />
				)}
			</Routes>
		</Style.AppContainer>
	);
}

const mapStateToProps = createStructuredSelector({
	resourceTokens: selectResourceTokens,
});

export default connect(mapStateToProps)(App);
