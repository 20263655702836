import { zodResolver } from '@hookform/resolvers/zod';
import {
	ArrowBackOutlined,
	Edit,
	EditOff,
	ForwardToInbox,
	MarkEmailRead,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { z } from 'zod';
import {
	selectForgotPasswordEmailError,
	selectForgotPasswordEmailResponseData,
	selectIsSendingForgotPasswordEmail,
} from '../../../redux/user/user.selectors';
import { ForgotPasswordOutputSchema } from '../../../schemas/user/authentication.schemas';
import { ForgotPasswordEmailSchema } from '../../../schemas/user/manage-self.schemas';
import * as Style from './forgot-password-email-sent.styles';

type updateEmailRequestSchema = z.infer<typeof ForgotPasswordEmailSchema>;

interface ForgotPasswordEmailSentProps {
	// PROPS
	// Handle Toggle Event for swiching b/w login/forgot password
	handleShowForgotPasswordForm: () => void;
	email: string;
	updateEmailRequest: (
		email: z.infer<typeof ForgotPasswordEmailSchema>
	) => void;

	// REDUX PROPS
	isSendingForgotPasswordEmail: boolean;
	sendForgotPasswordEmailError: AxiosError | string | null;
	selectForgotPasswordEmailResponseData: z.infer<
		typeof ForgotPasswordOutputSchema
	> | null;
}

const ForgotPasswordEmailSent = (props: ForgotPasswordEmailSentProps) => {
	// ====================== USEFORM ONSUBMIT HANDLING ======================
	const methods = useForm<updateEmailRequestSchema>({
		resolver: zodResolver(ForgotPasswordEmailSchema),
		mode: 'onTouched',
		reValidateMode: 'onSubmit',
	});

	// ====================== EMAIL HANDLING ======================
	const [emailText, setEmailText] = useState(methods.watch(`email`));

	// Watch for changes to email and update the emailText state
	useEffect(() => {
		if (props.email !== null) {
			setEmailText(props.email);
			methods.setValue(`email`, props.email);
		} else {
			setEmailText(methods.watch(`email`));
			methods.setValue(`email`, methods.watch(`email`));
		}
	}, [props.email, emailText]);

	const [disableEmail, setDisableEmail] = React.useState(true);

	const handleDisableEmail = () => {
		setDisableEmail(!disableEmail);
	};

	/* Update the user's email to send the forgot password email to
	 * the new email address and
	 * show error message if the email field is empty
	 */
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [errorMessageText, setErrorMessageText] = useState('');
	useEffect(() => {
		const email = methods.watch('email');

		// Watch for empty email input
		if (email && email.length === 0) {
			setErrorMessageText('Please enter an email');
			setShowErrorMessage(true);
		} else {
			// Watch for valid email input
			const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
			if (!emailRegex.test(email)) {
				setErrorMessageText('Please enter a valid email');
				setShowErrorMessage(true);
			} else {
				setShowErrorMessage(false);
			}
		}
	}, [methods.watch('email'), showErrorMessage, errorMessageText]);

	// function which is called when the user clicks on the `Request New Code`
	// button to send the forgot password email to the new email address
	const handleUpdateEmail = () => {
		if (
			!showErrorMessage &&
			props.selectForgotPasswordEmailResponseData !== null
		) {
			props.updateEmailRequest({
				email: methods.watch('email'),
			} as updateEmailRequestSchema);
			setDisableEmail(true);
		}
	};

	// Error handling
	const [errorText, setErrorText] = React.useState<string>('');
	useEffect(() => {
		if (props.sendForgotPasswordEmailError) {
			if (typeof props.sendForgotPasswordEmailError === 'string') {
				setErrorText(props.sendForgotPasswordEmailError);
			} else if (axios.isAxiosError(props.sendForgotPasswordEmailError)) {
				setErrorText(
					props.sendForgotPasswordEmailError.response?.data.detail
				);
			}
		}
	}, [props.sendForgotPasswordEmailError]);

	// Handle Click Event for opening email app
	const handleOpenEmailApp = () => {
		window.location.href = `mailto:`;
	};

	return (
		<Style.MainContainer>
			<Style.Container
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.8 }}
			>
				<MarkEmailRead className='emailIcon' />
				<Style.Heading>Check Your Email</Style.Heading>
				<Style.SubHeading>
					We sent a password reset link to
				</Style.SubHeading>
				<Style.InputFieldsContainer
					style={{
						borderColor: disableEmail
							? '#eaf2f5'
							: 'rgba(0, 64, 75, 0.74)',
					}}
				>
					<Style.InputField>
						<Style.InputFieldInput
							type='email'
							placeholder='Email'
							disabled={disableEmail}
							defaultValue={props.email}
							style={{
								color: disableEmail ? '#878c91' : '#000',
							}}
							{...methods.register('email')}
						/>
						<Style.EditButton onClick={handleDisableEmail}>
							{disableEmail ? (
								<Edit className='editIcon' />
							) : (
								<EditOff className='editIcon' />
							)}
						</Style.EditButton>
						{showErrorMessage && (
							<Style.Error>{errorMessageText}</Style.Error>
						)}
					</Style.InputField>
				</Style.InputFieldsContainer>
				<Style.BtnContainer>
					{methods.watch(`email`) !== props.email &&
						!showErrorMessage && (
							<Style.SendBtn
								type='submit'
								onClick={handleUpdateEmail}
							>
								{props.isSendingForgotPasswordEmail ? (
									<CircularProgress
										size={20}
										style={{ color: '#FFF' }}
									/>
								) : (
									<Style.ResendContainer>
										<ForwardToInbox className='sendEmailIcon' />
										Request New Code
									</Style.ResendContainer>
								)}
							</Style.SendBtn>
						)}
					<Style.EmailBtn type='button' onClick={handleOpenEmailApp}>
						Open Email App
					</Style.EmailBtn>
				</Style.BtnContainer>
				<Style.Links>
					<Style.Link onClick={handleUpdateEmail}>
						Didn&apos;t receive the code?{' '}
						<Style.Request>Request Again</Style.Request>
						<Style.Request>
							{props.isSendingForgotPasswordEmail && (
								<CircularProgress
									size={18}
									style={{
										color: '#10B1DD',
										position: 'absolute',
										marginLeft: '5px',
									}}
								/>
							)}
						</Style.Request>
					</Style.Link>
					<Style.Link onClick={props.handleShowForgotPasswordForm}>
						<ArrowBackOutlined /> Back to Login
					</Style.Link>
				</Style.Links>
				{errorText !== null && (
					<Style.ErrorText>{errorText}</Style.ErrorText>
				)}
			</Style.Container>
		</Style.MainContainer>
	);
};

const mapStateToProps = createStructuredSelector({
	isSendingForgotPasswordEmail: selectIsSendingForgotPasswordEmail,
	sendForgotPasswordEmailError: selectForgotPasswordEmailError,
	selectForgotPasswordEmailResponseData:
		selectForgotPasswordEmailResponseData,
});

export default connect(mapStateToProps)(ForgotPasswordEmailSent);
